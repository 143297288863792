/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertService } from '@fuse/components/alert';
import { InternalService, PaymentIntent } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { PaymentAppService } from 'app/core/services/payments.app.service';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'app-cancel-payment-dialog',
  templateUrl: './cancel-payment-dialog.component.html',
  styleUrls: ['./cancel-payment-dialog.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TilledLabelL1Component,
    MatSelectModule,
    MatOptionModule,
    FuseAlertComponent,
    CommonModule,
    FormCardComponent,
    TilledSelectComponent,
  ],
})
export class CancelPaymentDialogComponent extends ComponentBase implements OnInit {
  public cancelForm: FormGroup;
  public payment: PaymentIntent;
  public isMerchant: boolean;
  public paymentCanceled: boolean;

  public alertType: string;
  public alertTitle: string;
  public alertMessage: string;
  public alertDismissible: boolean;

  private accountId: string;

  private _displayAlert$ = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public displayAlert$ = this._displayAlert$.asObservable();

  private cancelError$$: Observable<string>;
  private cancelCreated$: Observable<boolean>;

  constructor(
    public matDialogRef: MatDialogRef<CancelPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _paymentAppService: PaymentAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _fuseAlertService: FuseAlertService,
    private _internalService: InternalService,
  ) {
    super();
    // Determine if this is a merchant or partner logged in
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
    });
    this.accountId = this._data.accountId;
    this.payment = this._data.payment;
  }

  async ngOnInit(): Promise<void> {
    this.cancelForm = this._formBuilder.group({
      reason: new FormControl<string | null>(null, [Validators.required]),
    });
  }

  public async submitCancelClicked(): Promise<void> {
    this._paymentAppService.payment$.pipe(takeUntil(this._unsubscribeAll)).subscribe((payment) => {
      if (payment) {
        this.paymentCanceled = true;
      }
    });

    await this._paymentAppService.cancelPayment({
      id: this.payment.id,
      tilledAccount: this.accountId,
      paymentIntentCancelParams: {
        cancellation_reason: this.cancelForm.get('reason').value,
      },
    });
  }

  public closeDialog(dialogResult?: any): void {
    this.matDialogRef.close(dialogResult);
  }
}
