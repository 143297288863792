<form-card
  [titleText]="!paymentCanceled ? 'Cancel Payment Link' : 'Payment Link Canceled'"
  [secondaryText]="
    !paymentCanceled
      ? 'Cancel this payment link to prevent payments from being processed using it.'
      : 'This payment link can no longer be used to collect a payment.'
  "
  [submitButton]="true"
  [submitText]="!paymentCanceled ? 'Cancel Payment Link' : 'View Payments'"
  [submitButtonBg]="!paymentCanceled ? 'warn-500' : 'tilled-primary'"
  (submitClick)="!paymentCanceled ? submitCancelClicked() : closeDialog()"
  [backButton]="!paymentCanceled"
  [backButtonText]="'Nevermind'"
  (backClick)="closeDialog()"
  [exitButton]="true"
  (exitClick)="closeDialog()"
  [submitDisabled]="false"
  [maxWidth]="null"
>
  <form *ngIf="!paymentCanceled" [formGroup]="cancelForm" class="">
    <div class="col-span-1 flex flex-col">
      <tilled-label-l1 secondary="true" class="pb-2.5">Cancellation reason</tilled-label-l1>
      <tilled-select
        [multiple]="false"
        placeholder="Select a cancellation reason"
        [controlName]="cancelForm.get('reason')"
        classOverride="w-full"
        data-test-id="reason"
        [options]="[
          { value: 'duplicate', label: 'Duplicate' },
          { value: 'fraudulent', label: 'Fraudulent' },
          { value: 'requested_by_customer', label: 'Requested by customer' },
          { value: 'abandoned', label: 'Abandoned' }
        ]"
      ></tilled-select>
    </div>
    <!-- Reason End -->
  </form>
</form-card>
<div *ngIf="displayAlert$ | async" class="flex w-full justify-start px-8 pt-2">
  <fuse-alert
    [type]="alertType"
    [appearance]="'outline'"
    [dismissible]="alertDismissible"
    [dismissed]="false"
    [name]="'cancelPaymentDialogAlertBox'"
  >
    <span fuseAlertTitle>{{ alertTitle }}</span>
    {{ alertMessage }}
  </fuse-alert>
</div>
