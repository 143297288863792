<form-card
  [submitButton]="true"
  [backButton]="busOwnerStep && currentPrincipal"
  [submitDisabled]="disabled$ | async"
  [submitText]="currentPrincipal ? saveText.replace('Add', 'Update') : saveText"
  [submitButtonBg]="(isWhiteLabel$ | async) ? 'black' : 'tilled-primary'"
  [backButtonText]="'Delete'"
  [backButtonBorder]="(isWhiteLabel$ | async) ? 'black' : 'tilled-primary'"
  [titleText]="currentPrincipal ? title.replace('Add', 'Update') : title"
  [styles]="'w-[36.5rem] p-4'"
  [minWidth]=""
  [maxWidth]="'max-w-120'"
  (exitClick)="dialogRef.close()"
  (submitClick)="onContinueClicked($event)"
  (backClick)="removePrincipal()"
  [capitalizeTitle]="false"
  [capitalizeSubmitText]="false"
  [testIds]="{ exitButton: 'close-dialog', submitButton: 'save-button' }"
  class="*:pb-2"
  [merchantApp]="true"
>
  <form
    data-test-id="representatives-step"
    class="w-full"
    [formGroup]="representativesFormArray"
    *ngIf="busOwnerStep !== undefined"
  >
    <div *ngFor="let group of representativesFormArray.controls; let i = index" [formGroup]="group">
      <!-- Only show form of currentPrincipal -->
      <ng-container *ngIf="group?.get('id')?.value === currentPrincipal?.id">
        <!--Details-->
        <div class="mb-2 mt-6 flex flex-row items-center">
          <mat-divider class="w-full"></mat-divider>
          <tilled-label-l1
            [styles]="'font-[600]'"
            class="flex whitespace-nowrap px-2 text-lg leading-normal tracking-normal"
          >
            Personal Details</tilled-label-l1
          >
          <mat-divider class="w-full"></mat-divider>
        </div>
        <div [class]="busOwnerStep ? '-mb-3' : ''">
          <div class="mb-3 flex flex-col">
            <div class="flex w-full flex-col">
              <tilled-input
                data-test-id="reps-first-name"
                class="w-full"
                name="firstName"
                label="First name"
                placeholder="Enter first name"
                tilledLabel="true"
                [errors]="group.get('firstName').errors?.['required'] && group.get('firstName').touched"
                errorMessage="First Name is Required."
              ></tilled-input>
              <tilled-input
                data-test-id="reps-last-name"
                class="w-full"
                name="lastName"
                label="Last name"
                placeholder="Enter last name"
                tilledLabel="true"
                [errors]="group.get('lastName').errors?.['required'] && group.get('lastName').touched"
                errorMessage="Last Name is Required."
              ></tilled-input>
            </div>
            <mat-hint
              *ngIf="
                !(
                  (group.get('firstName').errors && group.get('firstName').touched) ||
                  (group.get('lastName').errors && group.get('lastName').touched)
                )
              "
              id="field-hint"
              class="ml-px flex pt-1 text-sm"
            >
              {{ busOwnerStep ? "Enter the owner's name" : 'Enter your name' }} exactly as it is recorded with
              government agencies (i.e. IRS).
            </mat-hint>
          </div>
          <div
            class="col-span-1 flex flex-col pb-3"
            [ngClass]="forConsole ? 'sm:grid-cols-2' : ''"
            *ngIf="hasTsysProvider && !busOwnerStep"
          >
            <mat-checkbox
              data-test-id="reps-applicant"
              [color]="'primary'"
              [formControlName]="'isApplicant'"
              (change)="onIsApplicant(i)"
              class="-ml-2.5 text-white"
              [checked]="group.get('isApplicant')?.value"
            >
              <tilled-paragraph-p4
                [bold]="true"
                class="-ml-1.5 flex"
                [noColor]="true"
                [ngClass]="{
                  'text-warn': group.get('isApplicant').hasError('required') && group.get('isApplicant').touched
                }"
              >
                This individual will digitally sign this application and consents to a credit check.
              </tilled-paragraph-p4>
            </mat-checkbox>
            <div class="mt-1.5 flex items-center">
              <tilled-paragraph-p4 class="flex"> Why is a credit check needed? </tilled-paragraph-p4>
              <mat-icon
                class="mx-2 text-tilled-primary-500 icon-size-4.5"
                [svgIcon]="'heroicons_solid:question-mark-circle'"
                [matTooltipClass]="'spaced-tooltip'"
                [matTooltipPosition]="'right'"
                [matTooltip]="
                  'Credit checks ensure the application signer\'s financial responsibility, reducing fraud risk and ensuring compliance. This protects both the payment processor and the business.'
                "
              ></mat-icon>
            </div>
          </div>
          <div class="col-span-1 flex flex-col">
            <div class="w-full pb-2.5">
              <tilled-label-l1 secondary="true" class="pt-8">Job title</tilled-label-l1>
            </div>
            <tilled-select
              [multiple]="false"
              [options]="jobTitleMap"
              [placeholder]="'Select job title'"
              [controlName]="group.get('jobTitle')"
              data-test-id="reps-job-title"
              [classOverride]="'w-full mb-4'"
              [hint]="
                'If you don\'t see ' +
                (busOwnerStep ? 'their' : 'your') +
                ' exact job title, select the one which most closely matches ' +
                (busOwnerStep ? 'their' : 'your') +
                ' role and responsibilities.'
              "
              [errors]="group.get('jobTitle').errors?.['required'] && group.get('jobTitle').touched"
              errorMessage="Job title is Required."
            ></tilled-select>
          </div>
          <tilled-input
            data-test-id="reps-dob"
            name="dob"
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="group.controls.dob.errors && group.controls.dob.touched"
            [errorMessage]="
              group.controls.dob.errors?.['younger']
                ? 'Business owner must be older than 18.'
                : 'A valid date of birth is required.'
            "
          ></tilled-input>
          <tilled-input
            *ngIf="!hideSSN"
            data-test-id="reps-ssn"
            name="ssn"
            mask="000-00-0000"
            placeholder="{{
              representativesFormArray.value[i].ssn
                ? 'Already submitted. Click if you need to update the SSN.'
                : 'Enter SSN'
            }}"
            class="mb-5 flex flex-col"
            label="Social Security Number"
            tilledLabel="true"
            [errors]="group.get('ssn')?.errors && group.get('ssn').touched"
            [errorMessage]="'A valid SSN is required'"
            [hint]="'Per US regulations around anti-money laundering, we are required to collect the SSN.'"
            [sentenceCaseTitle]="false"
            [sentenceCasePlaceholder]="false"
          ></tilled-input>
          <tilled-input
            data-test-id="reps-phone-number"
            class="flex w-full flex-col"
            name="phone"
            [prefix]="group.get('phone')?.value?.includes('+') ? '' : selectedPhoneCode"
            placeholder="(555) 000-0000"
            [mask]="group.get('phone')?.value?.includes('+') ? '+0 (000) 000-0000' : '(000) 000-0000'"
            label="PHONE NUMBER"
            tilledLabel="true"
            [errors]="group.get('phone')?.errors && group.get('phone').touched"
            errorMessage="Must be a valid phone number."
            [prefixMultiple]="false"
            [prefixOptions]="phoneCodeMap"
            [prefixControlName]="group.get('phoneCode')"
            [prefixPlaceholder]="'US'"
          ></tilled-input>
          <ng-container *ngIf="hasTsysProvider && !busOwnerStep">
            <div class="grid w-full grid-cols-1 gap-x-6">
              <div class="col-span-1 flex flex-col">
                <tilled-input
                  data-test-id="reps-controller-email"
                  class="w-full"
                  name="email"
                  placeholder="your@email.com"
                  [sentenceCasePlaceholder]="false"
                  label="Email address"
                  tilledLabel="true"
                  toolTip="This email address will be used to verify the user for the digital signing step."
                  [errors]="group.get('email')?.errors && group.get('email').touched"
                  errorMessage="A valid email address is required."
                ></tilled-input>
              </div>
            </div>
            <div class="flex flex-col pt-2" *ngIf="!busOwnerStep">
              <div class="flex flex-row items-center">
                <mat-checkbox
                  class="-ml-2.5 text-white"
                  data-test-id="reps-tax-form-via-email"
                  [color]="'primary'"
                  formControlName="taxFormViaEmail"
                >
                  <tilled-paragraph-p4 [bold]="true" class="-ml-1.5 flex"
                    >I agree to receive my 1099-K via secure email.</tilled-paragraph-p4
                  >
                </mat-checkbox>
                <mat-icon
                  class="text-tilled-primary-500 icon-size-4.5"
                  [svgIcon]="'heroicons_solid:question-mark-circle'"
                  (mouseenter)="openMenu()"
                  (mouseleave)="closeMenu()"
                  [matMenuTriggerFor]="taxTooltip"
                  #menuTrigger="matMenuTrigger"
                ></mat-icon>
                <mat-menu #taxTooltip="matMenu" [hasBackdrop]="false" class="-ml-50 min-w-100 bg-tilled-primary">
                  <div
                    mat-menu-item
                    class="w-full cursor-text py-1"
                    (click)="$event.stopPropagation()"
                    (mouseenter)="openMenu()"
                    (mouseleave)="closeMenu()"
                    style="user-select: text"
                  >
                    <tilled-paragraph-p4 [white]="true">
                      A 1099-K is a report of payments you got during the year from:
                      <ul class="indented-list">
                        <li>Credit, debit or stored value cards such as gift cards (payment cards)</li>
                        <li>Payment apps or online marketplaces (third-party payment networks)</li>
                      </ul>
                      Third-party payment networks are required to file Form 1099-K with the IRS and provide a copy to
                      you when the gross payment amount is more than $600.
                      <a
                        href="https://www.irs.gov/businesses/understanding-your-form-1099-k"
                        class="underline"
                        target="_blank"
                      >
                        Learn more on the IRS website >
                      </a>
                    </tilled-paragraph-p4>
                  </div>
                </mat-menu>
              </div>
              <tilled-paragraph-p4 class="flex"
                >If left unchecked, your 1099K will be mailed to your business address.</tilled-paragraph-p4
              >
            </div>
          </ng-container>
          <div class="pt-2" *ngIf="!hideOwnershipQuestion">
            <div *ngIf="!busOwnerStep" class="col-span-1 flex flex-col" [ngClass]="forConsole ? 'sm:grid-cols-2' : ''">
              <mat-checkbox
                data-test-id="reps-more-than-25"
                [color]="'primary'"
                (change)="onOwnsMoreThan25Percent(!ownsMoreThan25Percent)"
                class="-ml-2.5 text-white"
                [checked]="ownsMoreThan25Percent"
              >
                <tilled-paragraph-p4 [bold]="true" class="-ml-1.5 flex">
                  This individual owns 25% or more of this business.
                </tilled-paragraph-p4>
              </mat-checkbox>
            </div>
            <tilled-input
              *ngIf="ownsMoreThan25Percent || busOwnerStep"
              data-test-id="reps-ownership-percentage"
              class="-mt-3 flex flex-col"
              name="percentageShareholding"
              label="Ownership percent"
              tilledLabel="true"
              placeholder="Enter %"
              [showPercent]="true"
              minValue="0"
              maxValue="100"
              mask="percent"
              [errors]="group.get('percentageShareholding').errors"
              [errorMessage]="
                group.get('percentageShareholding').errors && group.get('percentageShareholding').touched
                  ? group.get('percentageShareholding').errors?.['min']
                    ? 'Must be greater than' + group.get('percentageShareholding').errors?.['min']?.min + '%'
                    : group.get('percentageShareholding').errors?.['max']
                      ? 'Must not be greater than 100%'
                      : 'Ownership percentage value is required.'
                  : null
              "
              hint="Enter the % amount of the business owned by this individual"
            ></tilled-input>
          </div>
          <!-- Previous Bankruptcy - note: leaving out of UI for now (ngIf false) -->
          <!-- ng-container *ngIf="(controlProngIndex$ | async) === i" -->
          <ng-container *ngIf="false">
            <div class="flex flex-row items-center pt-4">
              <tilled-paragraph-p3>Has this individual ever had a personal or business bankruptcy?</tilled-paragraph-p3>
              <mat-icon
                class="mx-2 icon-size-5"
                [svgIcon]="'mat_outline:help_outline'"
                matTooltip="This information is used to get a better understanding of your financial history. Previous bankruptcies does not automatically disqualify you from the application process."
              ></mat-icon>
            </div>
            <mat-radio-group
              data-test-id="reps-previous-bankruptcy"
              class="flex space-x-4 pt-2"
              color="primary"
              [formControlName]="'previousBankruptcy'"
              (change)="onPreviousBankruptcy(i)"
            >
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <div class="pt-2" *ngIf="showPreviousBankruptcy$ | async">
              <tilled-paragraph-p3>Type of bankruptcy</tilled-paragraph-p3>
              <mat-radio-group
                data-test-id="reps-type-of-bankruptcy"
                class="-mb-2 flex space-x-4 pt-2"
                color="primary"
                [formControlName]="'isPersonal'"
              >
                <mat-radio-button [value]="true">Personal</mat-radio-button>
                <mat-radio-button [value]="false">Business</mat-radio-button>
              </mat-radio-group>
              <tilled-input
                data-test-id="reps-date-of-bankruptcy"
                name="fileDate"
                label="Bankruptcy filing date"
                placeholder="MM/DD/YYYY"
                [sentenceCasePlaceholder]="false"
                tilledLabel="true"
                mask="M0/d0/0000"
                [dropMaskCharacters]="false"
                [leadZeroDateTime]="true"
                [errors]="group.get('fileDate').errors?.['mask']"
                errorMessage="Bankruptcy filing date must be a valid date."
              ></tilled-input>
            </div>
          </ng-container>
          <!--Owner Current Residential Address-->
          <div class="mt-6 flex flex-row items-center">
            <mat-divider class="w-full"></mat-divider>
            <tilled-label-l1
              [styles]="'font-[600]'"
              class="flex whitespace-nowrap px-2 text-lg leading-normal tracking-normal"
            >
              Address</tilled-label-l1
            >
            <mat-divider class="w-full"></mat-divider>
          </div>
          <div>
            <div class="w-full pb-2.5 pt-4">
              <tilled-label-l1> Residential address </tilled-label-l1>
            </div>
            <app-autocomplete
              data-test-id="contact-current-street"
              class="w-full"
              name="currentStreet"
              [autocompleteAddress]="representativesFormArray.value[i].currentStreet"
              (setAddress)="setPrincipalAddress($event, i, 'current')"
              [hint]="
                group.get('currentStreet').errors && group.get('currentStreet').touched
                  ? null
                  : 'Enter the address where this business owner lives. P.O. boxes are not accepted.'
              "
            ></app-autocomplete>
            <mat-error
              class="flex pt-1.5 text-sm font-medium text-warn"
              *ngIf="group.get('currentStreet').errors && group.get('currentStreet').touched"
            >
              {{
                group.get('currentStreet').errors?.['required']
                  ? 'A residential address is required'
                  : 'P.O. boxes are not accepted.'
              }}
            </mat-error>
            <tilled-input
              [ngClass]="{
                'mt-2 flex flex-col': !(group.get('currentStreet').errors && group.get('currentStreet').touched)
              }"
              data-test-id="contact-current-street2"
              name="currentStreet2"
              label="Apt, Suite, etc. (Optional)"
              placeholder="Suite 1234"
              tilledLabel="true"
              [sentenceCaseTitle]="false"
            ></tilled-input>
            <div class="col-span-1 flex flex-col">
              <div class="w-full pb-2.5 pt-4">
                <tilled-label-l1 secondary="true" class="pt-8">State</tilled-label-l1>
              </div>
              <tilled-select
                [multiple]="false"
                [options]="stateCodeMap"
                [placeholder]="'Choose One'"
                [controlName]="group.get('currentState')"
                data-test-id="contact-current-state"
                groupName="MerchantAppBusinessContactForm"
                [classOverride]="'w-full'"
                [errors]="group.get('currentState').errors && group.get('currentState').touched"
                errorMessage="State is required."
              ></tilled-select>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-current-city"
                name="currentCity"
                label="CITY"
                placeholder="Louisville"
                tilledLabel="true"
                [errors]="group.get('currentCity').errors && group.get('currentCity').touched"
                errorMessage="City is required."
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-current-zip"
                name="currentZip"
                label="Zip code"
                tilledLabel="true"
                placeholder="80205"
                maxLength="10"
                [errors]="group.get('currentZip').errors && group.get('currentZip').touched"
                errorMessage="Zip code is required."
              ></tilled-input>
            </div>
            <div *ngIf="isCanadian" class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="reps-years-at-current-address"
                name="yearsAtCurrentAddress"
                label="# OF YEARS AT THIS ADDRESS"
                tilledLabel="true"
                placeholder="Enter #"
                minValue="1"
              ></tilled-input>
            </div>
          </div>
          <!--Owner Current Residential Address-->
          <div *ngIf="representativesFormArray.value[i].yearsAtCurrentAddress <= 3 && isCanadian">
            <div class="w-full pb-2.5 pt-4">
              <tilled-label-l1>Owner Previous Residential Address</tilled-label-l1>
            </div>
            <div>
              <app-autocomplete
                data-test-id="contact-previous-street"
                class="w-full"
                name="previousStreet"
                [autocompleteAddress]="representativesFormArray.value[i].previousStreet"
                (setAddress)="setPrincipalAddress($event, i, 'previous')"
              ></app-autocomplete>
            </div>
            <tilled-input
              data-test-id="contact-previous-street2"
              class="w-full"
              name="previousStreet2"
              label="STREET ADDRESS 2"
              placeholder="Suite / Apt / Unit (Optional)"
              tilledLabel="true"
            ></tilled-input>
            <div class="grid w-full grid-cols-1 gap-x-6">
              <div class="col-span-1 flex flex-col">
                <tilled-input
                  data-test-id="contact-previous-city"
                  name="previousCity"
                  label="CITY"
                  placeholder="Louisville"
                  tilledLabel="true"
                ></tilled-input>
              </div>
              <div class="col-span-1 flex flex-col">
                <div class="w-full pb-2.5 pt-4">
                  <tilled-label-l1 secondary="true" class="pt-8">State</tilled-label-l1>
                </div>
                <tilled-select
                  [multiple]="false"
                  [options]="stateCodeMap"
                  [placeholder]="'Choose One'"
                  [controlName]="group.get('previousState')"
                  classOverride="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full"
                  data-test-id="contact-previous-state"
                  groupName="MerchantAppBusinessContactForm"
                ></tilled-select>
              </div>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-previous-zip"
                name="previousZip"
                label="ZIP"
                placeholder="80205"
                tilledLabel="true"
                maxLength="10"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="reps-years-at-previous-address"
                name="yearsAtPreviousAddress"
                label="# OF YEARS AT THIS ADDRESS"
                tilledLabel="true"
                placeholder="Enter #"
                minValue="1"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="contact-previous-zip"
                name="previousZip"
                label="ZIP"
                placeholder="80205"
                tilledLabel="true"
                maxLength="10"
              ></tilled-input>
            </div>
            <div class="col-span-1 flex flex-col">
              <tilled-input
                data-test-id="reps-years-at-previous-address"
                name="yearsAtPreviousAddress"
                label="# OF YEARS AT THIS ADDRESS"
                tilledLabel="true"
                placeholder="Enter #"
                minValue="1"
              ></tilled-input>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</form-card>
