/* eslint-disable @typescript-eslint/member-ordering */
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { FilesAppService } from 'app/core/services/files.app.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { AccountDocumentsUploadComponent } from 'app/shared/account-documents/upload/account-documents-upload.component';
import { FileUploadComponent } from 'app/shared/file-upload/file-upload.component';
import { ChipColorClass, TilledChipComponent, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import {
  TilledHeadingH5Component,
  TilledParagraphP2Component,
  TilledParagraphP3Component,
} from 'app/shared/tilled-text';
import { mapSubtypeToTitle } from 'app/shared/utils/onboarding-utils';
import { isOlderThan, isValidYear } from 'app/shared/validators/dob.validator';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import {
  BulkDocumentSubmissionResponse,
  CharityDocumentData,
  DocumentDto,
  InternalAccount,
  MiddeskBusiness,
  ModelFile,
  OnboardingApplication,
  PAArticlesOfIncorporation,
  PABusinessLicense,
  PatriotActDetails,
  SubmitDocumentRequestParams,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'business-documents-step',
  templateUrl: './business-documents-step.component.html',
  styleUrl: './business-documents-step.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    AsyncPipe,
    TilledHeadingH5Component,
    TilledParagraphP3Component,
    TilledParagraphP2Component,
    TilledChipComponent,
    MatRadioModule,
    CommonModule,
    FileUploadComponent,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class BusinessDocumentsStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() merchantAccount: InternalAccount = null;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('documentsComponentAOI') documentsComponentAOI: AccountDocumentsUploadComponent;
  @ViewChild('documentsComponentBL') documentsComponentBL: AccountDocumentsUploadComponent;

  public businessDocumentsForm: FormGroup;
  public merchantApp: OnboardingApplication;
  private subscriptions: Subscription[] = [];
  public middeskBusiness: MiddeskBusiness;

  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.statesMap).map(
    ([value, label]) => ({ label, value }),
  );

  public fileTypes = [ModelFile.TypeEnum.PDF, ModelFile.TypeEnum.PNG, ModelFile.TypeEnum.JPG];
  public docSubTypes: typeof DocumentDto.SubtypeEnum = DocumentDto.SubtypeEnum;
  public description501c3 = ['501c3'];
  public filePurpose = ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION;
  private pendingFiles: number = 0;

  private allFiles$: Observable<ModelFile[]>;
  private subscription: Subscription;
  private _doc501c3$ = new BehaviorSubject<DocumentDto>(null);
  public doc501c3$ = this._doc501c3$.asObservable();
  private _file501c3$ = new BehaviorSubject<ModelFile[]>(null);
  public file501c3$ = this._file501c3$.asObservable();
  private _additionalDocs$ = new BehaviorSubject<DocumentDto[]>(null);
  public additionalDocs$ = this._additionalDocs$.asObservable();
  private _bankStatement$ = new BehaviorSubject<ModelFile[]>(null);
  public bankStatement$ = this._bankStatement$.asObservable();
  private _processingStatement$ = new BehaviorSubject<ModelFile[]>(null);
  public processingStatement$ = this._processingStatement$.asObservable();
  public activePanel: DocumentDto.SubtypeEnum = null;

  public isCharityOrReligiousOrg: boolean;
  public hasTsysProvider: boolean;
  public isWhiteLabel$: Observable<boolean>;

  public chipConfig: TilledChipConfig;

  private _showArticlesOfIncorp$ = new BehaviorSubject<boolean>(false);
  public showArticlesOfIncorp$ = this._showArticlesOfIncorp$.asObservable();
  private _showBusinessLicense$ = new BehaviorSubject<boolean>(false);
  public showBusinessLicense$ = this._showBusinessLicense$.asObservable();

  public account: InternalAccount;
  private _account$ = new Subject<InternalAccount>();
  public account$ = this._account$.asObservable();
  public mapSubtypeToTitle = mapSubtypeToTitle;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _documentsService: DocumentsAppService,
    private _authService: AuthService,
    private _accountAppService: AccountAppService,
    private _filesAppService: FilesAppService,
    private _brandingService: BrandingService,
  ) {
    super();
    this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
      this.hasTsysProvider = hasTsysProvider;
    });
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
  }

  ngOnInit(): void {
    this.allFiles$ = this._filesAppService.filesAll$;
    this.subscriptions.push(
      combineLatest([
        this._authService.account$,
        this._accountAppService.connectedAccount$.pipe(
          takeUntil(this._unsubscribeAll),
          // if connectedAccount$ never emits, use EMPTY observable
          catchError(() => EMPTY),
        ),
      ])
        .pipe(
          takeUntil(this._unsubscribeAll),
          map(([account, connectedAccount]) => connectedAccount || account),
          filter((account) => !!account),
        )
        .subscribe((account: InternalAccount) => {
          // Update this.account with the latest non-null value
          this.account = account;
          if (this.account) {
            this._account$.next(this.account);
            this._merchantAppService.getMiddeskBusiness(this.account.id);
          }
        }),
    );
    const validDocStatuses = [DocumentDto.StatusEnum.SUBMITTED, DocumentDto.StatusEnum.VERIFIED];
    this._documentsService.getAllDocuments(this.account.id, null);
    this._filesAppService.listAllFiles(this.account.id, [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION]);
    this.subscription = combineLatest([this._documentsService.documentsAll$, this.allFiles$]).subscribe(
      ([docs, files]) => {
        if (docs) {
          this._additionalDocs$.next(
            docs
              .filter(
                (doc) =>
                  doc.status !== DocumentDto.StatusEnum.REJECTED && doc.subtype !== DocumentDto.SubtypeEnum._501C3,
              )
              .filter((doc, index, self) => self.findIndex((d) => d.id === doc.id) === index),
          );
          const existingDocs = docs.filter(
            (doc) => doc?.subtype !== DocumentDto.SubtypeEnum._501C3 && doc?.status !== DocumentDto.StatusEnum.REJECTED,
          );
          docs = docs.filter((doc) => validDocStatuses.includes(doc.status));
          const doc501c3 = docs.find((doc) => doc.id === this.merchantApp?.legal_entity?.charity_document?.document_id);
          if (doc501c3) {
            this._doc501c3$.next(doc501c3);
            const file501c3 = files?.find((file) => file?.id === doc501c3.file_ids?.[0]);
            if (file501c3) {
              this._file501c3$.next([file501c3]);
            }
          }
          const bsFile = files?.find((file) =>
            existingDocs.some(
              (doc) => doc?.file_ids?.includes(file?.id) && doc?.subtype === DocumentDto.SubtypeEnum.BANK_STATEMENT,
            ),
          );
          const psFile = files?.find((file) =>
            existingDocs.some(
              (doc) =>
                doc?.file_ids?.includes(file?.id) && doc?.subtype === DocumentDto.SubtypeEnum.PROCESSING_STATEMENT,
            ),
          );
          if (!this._bankStatement$.getValue()?.length) {
            this._bankStatement$.next(bsFile ? [bsFile] : undefined);
          }
          if (!this._processingStatement$.getValue()?.length) {
            this._processingStatement$.next(psFile ? [psFile] : undefined);
          }
        }
      },
    );

    this.businessDocumentsForm = this._formBuilder.group({
      businessDocsCheck: new FormControl<boolean>(
        this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation
          ? true
          : this.merchantApp?.legal_entity?.patriot_act_details?.business_license
            ? false
            : null,
      ),
      articlesOfIncorpIssuedAt: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation.issued_at
          ? moment(
              this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation.issued_at ||
                this.middeskBusiness?.formation_date,
            ).format('MM/DD/YYYY')
          : null,
        [isValidYear(1600), isOlderThan(0)],
      ),
      articlesOfIncorpState: new FormControl<string>(
        SelectorTypes.statesMap.get(
          this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation.state ||
            this.middeskBusiness?.formation_state,
        ) || null,
      ),
      businessLicenseIssuedAt: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.patriot_act_details?.business_license.issued_at
          ? moment(this.merchantApp?.legal_entity?.patriot_act_details?.business_license.issued_at).format('MM/DD/YYYY')
          : null,
        [isValidYear(1600), isOlderThan(0)],
      ),
      businessLicenseExpiresAt: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.patriot_act_details?.business_license.expires_at
          ? moment(this.merchantApp?.legal_entity?.patriot_act_details?.business_license.expires_at).format(
              'MM/DD/YYYY',
            )
          : null,
        [isValidYear(1600)],
      ),
      businessLicenseState: new FormControl<string>(
        SelectorTypes.statesMap.get(this.merchantApp?.legal_entity?.patriot_act_details?.business_license.state) ||
          null,
      ),
      file501c3: new FormControl<string>(this._file501c3$.getValue()?.[0]?.id || null),
      // used for hidden element to define button width
      hidden: new FormControl<boolean>(true),
    });

    this.subscriptions.push(
      this._merchantAppService.merchantApplicationResponse$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((application) => {
          this.merchantApp = cloneDeep(application);
        }),
    );

    this.subscriptions.push(
      combineLatest([this._merchantAppService.middeskBusiness$])
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(([business]) => {
          this.middeskBusiness = business;
          this.resetApplication();
        }),
    );

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessDocumentsForm.disable();
          } else {
            this.businessDocumentsForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save, this.forConsole);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onContinueClicked(accountId?: string, stayOnStep?: boolean): void {
    this.businessDocumentsForm.markAllAsTouched();
    if (this.businessDocumentsForm.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.businessDocumentsForm.controls) {
      const control = this.businessDocumentsForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.subscriptions.push(
      this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
        if (hasTsysProvider) {
          this.hasTsysProvider = true;
          if (this.businessDocumentsForm.value.businessDocsCheck) {
            // Articles of Incorporation
            let paDetails = this.merchantApp.legal_entity.patriot_act_details;
            if (!paDetails) {
              paDetails = {} as PatriotActDetails;
            }
            paDetails.business_license = null;
            paDetails.articles_of_incorporation = {
              state: (this.businessDocumentsForm.value.articlesOfIncorpState
                ? [...SelectorTypes.statesMap].find(
                    ([key, val]) => key === this.businessDocumentsForm.value.articlesOfIncorpState,
                  )[0]
                : null) as PAArticlesOfIncorporation.StateEnum,
              issued_at: moment(this.businessDocumentsForm.value.articlesOfIncorpIssuedAt, 'MM-DD-YYYY').toISOString(),
            };
            this.merchantApp.legal_entity.patriot_act_details = paDetails;
          } else {
            // Business License
            let paDetails = this.merchantApp.legal_entity.patriot_act_details;
            if (!paDetails) {
              paDetails = {} as PatriotActDetails;
            }
            paDetails.articles_of_incorporation = null;
            paDetails.business_license = {
              state: (this.businessDocumentsForm.value.businessLicenseState
                ? [...SelectorTypes.statesMap].find(
                    ([key, val]) => key === this.businessDocumentsForm.value.businessLicenseState,
                  )[0]
                : null) as PABusinessLicense.StateEnum,
              issued_at: moment(this.businessDocumentsForm.value.businessLicenseIssuedAt, 'MM-DD-YYYY').toISOString(),
              expires_at: moment(this.businessDocumentsForm.value.businessLicenseExpiresAt, 'MM-DD-YYYY').toISOString(),
              name: this.merchantApp.legal_entity.legal_name,
            };
            this.merchantApp.legal_entity.patriot_act_details = paDetails;
          }
        } else {
          this.merchantApp.legal_entity.patriot_act_details = {} as PatriotActDetails;
          this.merchantApp.legal_entity.patriot_act_details.articles_of_incorporation = {
            state: PAArticlesOfIncorporation.StateEnum.AK,
            issued_at: moment('01-01-2000', 'MM-DD-YYYY').toISOString(),
          };
        }
      }),
    );
    this._merchantAppService.updateProviderData();

    this._merchantAppService.updateMerchantApplication(
      this.merchantApp,
      this.stepNumber + (stayOnStep ? 0 : 1),
      accountId,
    );
  }

  private isAppUnsaved(): boolean {
    return false;
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;
    const aoiIssuedAt =
      ble?.patriot_act_details?.articles_of_incorporation?.issued_at || this.middeskBusiness?.formation_date;
    const aoiState =
      ble?.patriot_act_details?.articles_of_incorporation?.state || this.middeskBusiness?.formation_state;

    this.isCharityOrReligiousOrg = ble?.is_501c3 && this.hasTsysProvider;

    if (ble?.patriot_act_details?.articles_of_incorporation) {
      this.businessDocumentsForm.patchValue({ businessDocsCheck: true });
      this._showBusinessLicense$.next(false);
      this._showArticlesOfIncorp$.next(true);
    } else if (ble?.patriot_act_details?.business_license) {
      this.businessDocumentsForm.patchValue({ businessDocsCheck: false });
      this._showArticlesOfIncorp$.next(false);
      this._showBusinessLicense$.next(true);
    }

    this.businessDocumentsForm.controls['articlesOfIncorpIssuedAt'].setValue(
      aoiIssuedAt ? moment(aoiIssuedAt).format('MM/DD/YYYY') : null,
    );

    this.businessDocumentsForm.controls['articlesOfIncorpState'].setValue(
      aoiState ? [...SelectorTypes.statesMap].find(([key, val]) => key === aoiState)[0] : null,
    );

    this.businessDocumentsForm.controls['businessLicenseIssuedAt'].setValue(
      ble?.patriot_act_details?.business_license?.issued_at
        ? moment(ble?.patriot_act_details?.business_license?.issued_at).format('MM/DD/YYYY')
        : null,
    );
    this.businessDocumentsForm.controls['businessLicenseExpiresAt'].setValue(
      ble?.patriot_act_details?.business_license?.expires_at
        ? moment(ble?.patriot_act_details?.business_license?.expires_at).format('MM/DD/YYYY')
        : null,
    );
    const blState = ble?.patriot_act_details?.business_license?.state
      ? [...SelectorTypes.statesMap].find(([key, val]) => key === ble?.patriot_act_details?.business_license?.state)[0]
      : null;
    this.businessDocumentsForm.controls['businessLicenseState'].setValue(blState);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  public readable(input: string): string {
    let pieces = input.toLowerCase().split('_');
    for (let i = 0; i < pieces.length; i++) {
      if (!(pieces[i] === 'and' || pieces[i] === 'of' || pieces[i] === 'to' || pieces[i] === 'to')) {
        pieces[i] = pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1);
      }
    }
    return pieces.join(' ');
  }

  public onBusinessDocsChange(): void {
    const provideArticles = this.businessDocumentsForm.value.businessDocsCheck;
    if (provideArticles) {
      this._showArticlesOfIncorp$.next(true);
      this._showBusinessLicense$.next(false);
    }
    if (provideArticles === false) {
      this._showArticlesOfIncorp$.next(false);
      this._showBusinessLicense$.next(true);
    }
    if (provideArticles === null) {
      this._showArticlesOfIncorp$.next(false);
      this._showBusinessLicense$.next(false);
    }
  }

  documentSubmitted(response: BulkDocumentSubmissionResponse[]) {
    const provideArticles = this.businessDocumentsForm.value.businessDocsCheck;
    if (response && response[0].status === BulkDocumentSubmissionResponse.StatusEnum.SUCCEEDED) {
      if (provideArticles) {
        this.businessDocumentsForm.patchValue({ articlesOfIncorpDocumentId: response[0].document_id });
      } else {
        this.businessDocumentsForm.patchValue({ businessLicenseDocumentId: response[0].document_id });
      }

      this.onContinueClicked(this.account?.id, true);
    }
  }

  removeUploadedDocument(docId: string, skipSave?: boolean) {
    if (!docId) {
      return;
    }
    const request$ = this._documentsService.updateDocument(this.account.id, docId, {
      status: DocumentDto.StatusEnum.REQUESTED,
    });

    const provideArticles = this.businessDocumentsForm.value.businessDocsCheck;
    this.subscriptions.push(
      request$.subscribe({
        next: (response) => {
          if (!response) {
            return;
          }
          if (!skipSave) {
            if (provideArticles) {
              this.businessDocumentsForm.patchValue({ articlesOfIncorpDocumentId: null });
            } else {
              this.businessDocumentsForm.patchValue({ businessLicenseDocumentId: null });
            }

            this.onContinueClicked(this.account?.id, true);
          }
          this.documentsComponentAOI.refresh();
          this.documentsComponentBL.refresh();
        },
      }),
    );
  }

  fileDeleted(fileId: string, docId: string): void {
    if (!fileId || !docId) {
      return;
    }
    this._documentsService
      .updateDocument(this.account.id, docId, {
        status: DocumentDto.StatusEnum.REQUESTED,
      })
      .subscribe((response) => {
        if (response) {
          this._documentsService.getAllDocuments(this.account.id, null);
          if (response.subtype === DocumentDto.SubtypeEnum._501C3) {
            this._file501c3$.next(null);
            this.merchantApp.legal_entity.charity_document.status = null;
            this.businessDocumentsForm.patchValue({ file501c3: null });
            this.onContinueClicked(this.account?.id, true);
          } else {
            this.activePanel = response.subtype;
            this._filesAppService.deleteFile({ tilledAccount: this.account.id, id: fileId }).subscribe(() => {});
          }
        }
      });
  }

  public pendingFilesChange(numOfFiles: number): void {
    this.pendingFiles = numOfFiles;
  }

  fileUploaded(file: ModelFile, docId: string): void {
    if (!file || !docId) {
      return;
    }

    const params: SubmitDocumentRequestParams = {
      tilledAccount: this.account.id,
      id: docId,
      documentSubmitRequestParams: {
        file_ids: [file.id],
      },
    };
    this._documentsService.submitDocument(params).subscribe((response) => {
      if (response) {
        switch (response.subtype) {
          case DocumentDto.SubtypeEnum._501C3:
            this._doc501c3$.next(response);
            this._file501c3$.next([file]);
            this.businessDocumentsForm.patchValue({ file501c3: file.id });
            this.onContinueClicked(this.account?.id, true);
            break;
          case DocumentDto.SubtypeEnum.BANK_STATEMENT:
            this._bankStatement$.next([file]);
            this._documentsService.getAllDocuments(this.account.id, null);
            this.activePanel = DocumentDto.SubtypeEnum.BANK_STATEMENT;
            break;
          case DocumentDto.SubtypeEnum.PROCESSING_STATEMENT:
            this._processingStatement$.next([file]);
            this._documentsService.getAllDocuments(this.account.id, null);
            this.activePanel = DocumentDto.SubtypeEnum.PROCESSING_STATEMENT;
            break;
          default:
            break;
        }
      }
    });
  }

  public tilledChipConfig(type: 'patriotAct' | '501c3'): TilledChipConfig {
    if (!type) {
      return;
    }
    if (type === 'patriotAct') {
      const AOI = this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation;
      const BL = this.merchantApp?.legal_entity?.patriot_act_details?.business_license;
      const submitted =
        (BL?.expires_at && BL?.issued_at && BL?.name && BL?.state) || (AOI?.issued_at && AOI?.state) ? true : false;
      return {
        text: submitted ? 'SUBMITTED' : 'REQUESTED',
        toolTip: null,
        color: submitted ? ChipColorClass.OPAQUE_GREEN : ChipColorClass.OPAQUE_RED,
      };
    }
    if (type === '501c3') {
      const validDocStatuses = [CharityDocumentData.StatusEnum.SUBMITTED, CharityDocumentData.StatusEnum.VERIFIED];
      const submitted = validDocStatuses.includes(this.merchantApp?.legal_entity?.charity_document?.status);
      return {
        text: this.merchantApp?.legal_entity?.charity_document?.status?.replace('_', ' ').toUpperCase() || 'REQUESTED',
        toolTip: null,
        color: submitted ? ChipColorClass.OPAQUE_GREEN : ChipColorClass.OPAQUE_RED,
      };
    }
  }
}
