<form-card
  [titleText]="'Revoke Access'"
  [secondaryText]="subtitle"
  [submitButton]="true"
  [submitText]="'Revoke Access'"
  [submitButtonBg]="'warn-500'"
  [backButton]="true"
  [backButtonText]="'Cancel'"
  (backClick)="closeDialog()"
  [exitButton]="true"
  (exitClick)="closeDialog()"
  [submitDisabled]="false"
  [displayAlert]="false"
>
  <div class="-mt-4"></div>
  <ng-container *ngIf="isMultiAccountUser">
    <mat-checkbox
      class="-ml-2.5 pt-3 font-openSans"
      [color]="'primary'"
      [checked]="isRevokeAll"
      (change)="isRevokeAllCheckboxChanged($event.checked)"
      >Revoke access from all associated accounts</mat-checkbox
    >
    <div class="text-secondary font-openSans text-md italic">{{ checkboxSubtitle }}</div>
  </ng-container>
</form-card>
