<div class="top-of-form"></div>
<merchant-app-card
  [title]="'Business documents'"
  [description]="'Help us verify your business by answering a few questions about your business registration documents'"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
  [bypassHalfWidth]="true"
>
  <!-- used for hidden element to define button width -->
  <form class="w-full" [formGroup]="businessDocumentsForm">
    <tilled-input name="hidden" class="pointer-events-none invisible -mb-13 flex opacity-0"> </tilled-input>
  </form>
  <div class="w-full">
    <!-- Business verification document (Business Documents/Patriot Act Details) -->
    <div
      class="documents-accordion-wrapper mb-3 flex items-center self-center rounded-md"
      [ngClass]="{
        'tilled-gradient': !(isWhiteLabel$ | async)
      }"
    >
      <mat-accordion class="documents-accordion z-50 w-full rounded-md bg-white">
        <mat-expansion-panel
          class="my-2"
          [ngClass]="{
            'border-2 border-black': (isWhiteLabel$ | async)
          }"
        >
          <mat-expansion-panel-header class="mx-5 px-0 *:flex-row-reverse *:justify-end">
            <mat-panel-title class="space-x-4">
              <tilled-chip [chipConfig]="tilledChipConfig('patriotAct')"></tilled-chip>
              <tilled-heading-h5 class="">Business verification document</tilled-heading-h5></mat-panel-title
            >
          </mat-expansion-panel-header>
          <form data-test-id="verify-business-docs-form" class="-mr-8 mb-2" [formGroup]="businessDocumentsForm">
            <div>
              <div class="items-left mr-8 flex flex-col">
                <div class="flex w-full flex-col">
                  <tilled-paragraph-p2 class="z-10 -mt-2" [bold]="true">Choose document</tilled-paragraph-p2>
                  <mat-radio-group
                    data-test-id="business-docs-check"
                    class="flex flex-col -space-y-2"
                    color="primary"
                    formControlName="businessDocsCheck"
                    (change)="onBusinessDocsChange()"
                  >
                    <mat-radio-button [value]="true">Articles of Incorporation</mat-radio-button>
                    <mat-radio-button [value]="false">Business License</mat-radio-button>
                  </mat-radio-group>

                  <!-- Articles of Incorporation -->
                  <div [ngClass]="{ hidden: !(showArticlesOfIncorp$ | async) }">
                    <tilled-input
                      data-test-id="business-docs-aoi-issued-at"
                      name="articlesOfIncorpIssuedAt"
                      label="Issue date"
                      placeholder="MM/DD/YYYY"
                      [sentenceCasePlaceholder]="false"
                      tilledLabel="true"
                      mask="M0/d0/0000"
                      [dropMaskCharacters]="false"
                      [leadZeroDateTime]="true"
                      [errors]="
                        businessDocumentsForm.get('articlesOfIncorpIssuedAt')?.errors &&
                        businessDocumentsForm.get('articlesOfIncorpIssuedAt').touched
                      "
                      [errorMessage]="
                        businessDocumentsForm.get('articlesOfIncorpIssuedAt')?.errors?.['younger']
                          ? 'Issue date must be in the past.'
                          : 'Issue date must be a valid date.'
                      "
                    ></tilled-input>
                    <div class="w-full pb-2.5 pt-4">
                      <tilled-label-l1 secondary="true">State</tilled-label-l1>
                    </div>
                    <tilled-select
                      [multiple]="false"
                      [options]="stateCodeMap"
                      [placeholder]="'Choose One'"
                      [controlName]="businessDocumentsForm.get('articlesOfIncorpState')"
                      classOverride="pb-6"
                      [classOverride]="'w-full'"
                      data-test-id="business-docs-aoi-state"
                    ></tilled-select>
                  </div>

                  <!-- Business License -->
                  <div [ngClass]="{ hidden: !(showBusinessLicense$ | async) }">
                    <div class="w-full pb-2.5 pt-4">
                      <tilled-label-l1 secondary="true">State</tilled-label-l1>
                    </div>
                    <tilled-select
                      [multiple]="false"
                      [options]="stateCodeMap"
                      [placeholder]="'Choose One'"
                      [controlName]="businessDocumentsForm.get('businessLicenseState')"
                      [classOverride]="'w-full'"
                      data-test-id="business-docs-license-state"
                    ></tilled-select>
                    <tilled-input
                      data-test-id="business-docs-license-issued-at"
                      name="businessLicenseIssuedAt"
                      label="Issue date"
                      placeholder="MM/DD/YYYY"
                      [sentenceCasePlaceholder]="false"
                      tilledLabel="true"
                      mask="M0/d0/0000"
                      [dropMaskCharacters]="false"
                      [leadZeroDateTime]="true"
                      [errors]="
                        businessDocumentsForm.get('businessLicenseIssuedAt')?.errors &&
                        businessDocumentsForm.get('businessLicenseIssuedAt').touched
                      "
                      [errorMessage]="
                        businessDocumentsForm.get('businessLicenseIssuedAt')?.errors?.['younger']
                          ? 'Issue date must be in the past.'
                          : 'Issue date must be a valid date.'
                      "
                    ></tilled-input>
                    <tilled-input
                      data-test-id="business-docs-license-expires-at"
                      name="businessLicenseExpiresAt"
                      label="Expiry date"
                      placeholder="MM/DD/YYYY"
                      [sentenceCasePlaceholder]="false"
                      tilledLabel="true"
                      mask="M0/d0/0000"
                      [dropMaskCharacters]="false"
                      [leadZeroDateTime]="true"
                      [errors]="businessDocumentsForm.get('businessLicenseExpiresAt').errors?.['mask']"
                      errorMessage="Expiry date must be a valid date."
                    ></tilled-input>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <!-- Requested documentation (Currently only 501c3) -->
    <div
      *ngIf="isCharityOrReligiousOrg"
      class="documents-accordion-wrapper mb-3 flex items-center self-center rounded-md"
      [ngClass]="{
        'tilled-gradient': !(isWhiteLabel$ | async)
      }"
    >
      <mat-accordion class="documents-accordion z-50 w-full rounded-md bg-white">
        <mat-expansion-panel
          class="my-2"
          [ngClass]="{
            'border-2 border-black': (isWhiteLabel$ | async)
          }"
        >
          <mat-expansion-panel-header class="mx-5 px-0 *:flex-row-reverse *:justify-end">
            <mat-panel-title class="space-x-4">
              <tilled-chip [chipConfig]="tilledChipConfig('501c3')"></tilled-chip>
              <tilled-heading-h5 class="">501(c)(3) letter</tilled-heading-h5></mat-panel-title
            >
          </mat-expansion-panel-header>
          <!-- 501(c)(3) upload -->
          <form data-test-id="501c3-business-docs-form" class="-mr-8 mb-2" [formGroup]="businessDocumentsForm">
            <div>
              <div class="items-left mr-8 flex flex-col">
                <div class="flex w-full flex-col">
                  <div>
                    <app-file-upload
                      data-test-id="501c3-business-docs-upload"
                      class="-ml-1 flex flex-col *:flex *:flex-col *:pr-10"
                      [isPartnerApp]="false"
                      [isMerchantApp]="true"
                      [purpose]="filePurpose"
                      [allowedFileTypes]="fileTypes"
                      [fileDescriptions]="description501c3"
                      [existingFiles$]="file501c3$"
                      [allowMultipleFiles]="false"
                      [slim]="true"
                      [fileCountLimit]="1"
                      (fileDeleted)="fileDeleted($event, this.merchantApp?.legal_entity?.charity_document?.document_id)"
                      (fileUploaded)="
                        fileUploaded($event, this.merchantApp?.legal_entity?.charity_document?.document_id)
                      "
                      (pendingFilesChange)="pendingFilesChange($event)"
                      [fileAccountId]="forConsole ? merchantAccount.id : account.id"
                    ></app-file-upload>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <!-- Additional required onboarding docs -->
    <ng-container *ngFor="let doc of additionalDocs$ | async">
      <div
        *ngIf="doc?.id"
        class="documents-accordion-wrapper mb-3 flex items-center self-center rounded-md"
        [ngClass]="{
          'tilled-gradient': !(isWhiteLabel$ | async)
        }"
      >
        <mat-accordion class="documents-accordion z-50 w-full rounded-md bg-white">
          <mat-expansion-panel
            class="my-2"
            [ngClass]="{
              'border-2 border-black': (isWhiteLabel$ | async)
            }"
            [expanded]="activePanel === doc.subtype"
          >
            <mat-expansion-panel-header class="mx-5 px-0 *:flex-row-reverse *:justify-end">
              <mat-panel-title class="space-x-4">
                <tilled-chip
                  [chipConfig]="{
                    text: doc.status.toUpperCase(),
                    color: doc.status === 'requested' ? 'opaque_red' : 'opaque_green'
                  }"
                ></tilled-chip>
                <tilled-heading-h5 class="">{{ mapSubtypeToTitle(doc.subtype) }}</tilled-heading-h5>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [data-test-id]="doc.subtype.replace('_', '-') + '-business-docs-form'" class="-mr-8 mb-2">
              <div>
                <div class="items-left mr-8 flex flex-col">
                  <div class="flex w-full flex-col">
                    <div>
                      <app-file-upload
                        *ngIf="doc?.status === 'requested'"
                        [data-test-id]="doc.subtype.replace('_', '-') + '-business-docs-upload'"
                        class="-ml-1 flex flex-col *:flex *:flex-col *:pr-10"
                        [isPartnerApp]="false"
                        [isMerchantApp]="true"
                        [purpose]="filePurpose"
                        [allowedFileTypes]="fileTypes"
                        [existingFiles$]="
                          doc.subtype === docSubTypes.BANK_STATEMENT ? bankStatement$ : processingStatement$
                        "
                        [fileDescriptions]="[mapSubtypeToTitle(doc.subtype)]"
                        [allowMultipleFiles]="false"
                        [slim]="true"
                        (fileDeleted)="fileDeleted($event, doc.id)"
                        (fileUploaded)="fileUploaded($event, doc.id)"
                        (pendingFilesChange)="pendingFilesChange($event)"
                      >
                      </app-file-upload>
                      <!-- Hacky mimic of file-upload component, since previously uploaded files were not appearing -->
                      <ng-container *ngIf="doc?.status === 'submitted'">
                        <div
                          class=""
                          *ngFor="
                            let file of doc.subtype === docSubTypes.BANK_STATEMENT
                              ? (bankStatement$ | async)
                              : (processingStatement$ | async);
                            let i = index
                          "
                        >
                          <div
                            class="mb-4 flex cursor-pointer flex-col rounded-3xl border border-solid border-tilled-neutral-400 sm:pl-6 sm:pr-2"
                          >
                            <div class="flex h-[70px] flex-row items-center">
                              <mat-icon class="text-green-500 icon-size-5" svgIcon="heroicons_solid:check"></mat-icon>
                              <tilled-paragraph-p3
                                [bold]="true"
                                [truncate]="true"
                                class="overflow-hidden whitespace-nowrap pl-2"
                                >{{ file.title.split(':').pop() + '.' + file.type }}</tilled-paragraph-p3
                              >
                              <tilled-paragraph-p3 [bold]="true" class="w-fit pl-2">{{
                                '(' +
                                  (file.size / 1024).toFixed(1) +
                                  ((file.size / 1024).toFixed(1) > 1000 ? 'MB' : 'KB')
                              }}</tilled-paragraph-p3>
                              <div class="ml-auto flex flex-row">
                                <button
                                  mat-icon-button
                                  (click)="fileDeleted(file.id, doc.id)"
                                  matTooltip="Delete uploaded file"
                                  [data-test-id]="doc.subtype.replace('_', '-') + '-delete-btn' + i"
                                >
                                  <mat-icon
                                    class="text-tilled-primary icon-size-5"
                                    svgIcon="heroicons_solid:trash"
                                  ></mat-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</merchant-app-card>
