import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isPoBox(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();
    if (!value) {
      return null;
    }
    return /^\bp\.?\s*[o0]\.?(?:\s*Box)?\b/gi.test(value) ? { poBox: true } : null;
  };
}
