import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountRole, User } from '@tilled-api-client';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';

@Component({
  selector: 'app-revoke-access',
  templateUrl: './revoke-access-dialog.component.html',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatCheckboxModule, CommonModule, FormCardComponent],
})
export class RevokeAccessDialogComponent implements OnInit {
  //@Output() userInviteData;

  public isMultiAccountUser = false;
  public isRevokeAll = false;
  public checkboxSubtitle: string;
  public subtitle: string;

  private _deleteableAccountRoles: AccountRole[] = [];

  constructor(
    public dialogRef: MatDialogRef<RevokeAccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: IRevokeAccessDialogComponentData,
  ) {}

  ngOnInit(): void {
    this._deleteableAccountRoles = this._data.user.account_roles || [];
    //this._deleteableAccountRoles = this._data.user.account_roles?.filter((au) => au.role !== 'owner' && au.role !== 'merchant_owner') || [];
    this.isMultiAccountUser = this._deleteableAccountRoles.length > 1;

    this.updateSubtitles();
  }

  public revokeAccess(): void {
    let revokeAccountIds = [this._data.accountId];
    if (this.isRevokeAll) {
      revokeAccountIds = this._deleteableAccountRoles.map((au) => au.account_id);
    }

    const response: IRevokeAccessDialogComponentResponse = {
      revokeAccountIds,
    };
    this.dialogRef.close(response);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public isRevokeAllCheckboxChanged(checked: boolean): void {
    this.isRevokeAll = checked;
    this.updateSubtitles();
  }

  private updateSubtitles(): void {
    if (this.isMultiAccountUser) {
      const numOfOwners = this._deleteableAccountRoles.filter((au) => au.role === 'merchant_owner').length;
      const numOfAdmins = this._deleteableAccountRoles.filter((au) => au.role === 'merchant_admin').length;

      let extraText = '';
      if (numOfOwners && numOfAdmins) {
        extraText = ` (${numOfOwners} as owner, ${numOfAdmins} as admin)`;
      }

      this.subtitle = `${this._data.user.name} is currently associated with ${this._data.user.account_roles?.length} merchant accounts${extraText}.`;

      if (this.isRevokeAll) {
        this.checkboxSubtitle = `The user will lose access to ${this._deleteableAccountRoles.length} merchant accounts.`;
      } else {
        this.checkboxSubtitle = `If left unselected, the user will only lose access to ${this._data.accountName}'s account.`;
      }
    } else {
      this.subtitle = `${this._data.user.name} will lose access to ${this._data.accountName}'s account.`;
      this.checkboxSubtitle = null;
    }
  }
}

export interface IRevokeAccessDialogComponentData {
  user: User;
  accountId: string;
  accountName: string;
}

export interface IRevokeAccessDialogComponentResponse {
  revokeAccountIds: string[];
}
