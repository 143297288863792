import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { MerchantAppAlertComponent } from 'app/shared/cards/merchant-application/merchant-app-alert/merchant-app-alert.component';
import { VerificationAlertConfig } from 'app/shared/merchant-app-steps/business-details/business-details-step.component';
import { environment } from 'environments/environment';
import { cloneDeep, round } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import {
  InternalPartnerOnboardingDefaultSettings,
  OnboardingApplication,
  PricingTemplate,
  ProcessingVolume,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH5Component } from '../../tilled-text/tilled-heading/tilled-heading-h5.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'processing-volumes-merchant-step',
  templateUrl: './processing-volumes-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledHeadingH5Component,
    TilledParagraphP3Component,
    TilledInputComponent,
    MatDividerModule,
    CommonModule,
    MerchantAppAlertComponent,
  ],
})
export class ProcessingVolumesMerchantStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() partnerDefaultSettings$: Observable<InternalPartnerOnboardingDefaultSettings>;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];
  public processingVolumesForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public cardMin: number = 1;
  public cardMax: number = 100000000;
  public debitMin: number = 1;
  public debitMax: number = 100000000;
  public monthMin: number = 1;
  public monthMax: number = 100000000;

  public volumeRangeTypes = SelectorTypes.YearlyVolumeRangeTypes;

  public hasCard: boolean = false;
  public hasDebit: boolean = false;

  public tilledInputWidth: any;
  public reviewAlert: VerificationAlertConfig = null;
  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
  ) {
    super();
    this.partnerDefaultSettings$ = this._merchantAppService.partnerOnboardingDefaults$;
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  public onResize(event: any): void {
    this.tilledInputWidth =
      document?.querySelector('tilled-input mat-form-field')?.clientWidth + 'px' ||
      document?.querySelector('tilled-select mat-form-field')?.clientWidth + 'px';
  }

  ngOnInit(): void {
    this.processingVolumesForm = this._formBuilder.group({
      averageMonthlyTransactions: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.monthly_transaction_count || null,
        [Validators.min(1)],
      ),
      averageMonthlyVolume: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.monthly_processing_volume
          ? this.merchantApp.legal_entity.processing_volume.monthly_processing_volume / 100
          : null,
        [Validators.min(1), Validators.max(100000000)],
      ),
      averageTransactionAmountCard: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.average_transaction_amount_card
          ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card / 100
          : null,
        [Validators.min(1), Validators.max(100000000)],
      ),
      averageTransactionAmountDebit: new FormControl<number | null>(
        this.merchantApp?.legal_entity?.processing_volume?.average_transaction_amount_debit
          ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_debit / 100
          : null,
        [Validators.min(1), Validators.max(100000000)],
      ),
    });

    this.partnerDefaultSettings$.subscribe((result) => {
      if (result) {
        if (result.card_transaction_min || result.card_transaction_max) {
          this.cardMin = result.card_transaction_min / 100 || 1;
          this.cardMax = result.card_transaction_max / 100 || 10000000;
          this.processingVolumesForm
            .get('averageTransactionAmountCard')
            .setValidators([Validators.min(this.cardMin), Validators.max(this.cardMax)]);
        }

        if (result.debit_transaction_min || result.debit_transaction_max) {
          this.debitMin = result.debit_transaction_min / 100 || 1;
          this.debitMax = result.debit_transaction_max / 100 || 10000000;
          this.processingVolumesForm
            .get('averageTransactionAmountDebit')
            .setValidators([Validators.min(this.debitMin), Validators.max(this.debitMax)]);
          this.processingVolumesForm.get('averageTransactionAmountDebit').updateValueAndValidity();
        }

        if (result.monthly_volume_min || result.monthly_volume_max) {
          this.monthMin = result.monthly_volume_min / 100 || 1;
          this.monthMax = result.monthly_volume_max / 100 || 10000000;
          this.processingVolumesForm
            .get('averageMonthlyVolume')
            .setValidators([Validators.min(this.monthMin), Validators.max(this.monthMax)]);
        }
      }
    });
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.processingVolumesForm.disable();
          } else {
            this.processingVolumesForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    document.dispatchEvent(new MouseEvent('mousemove'));
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onContinueClicked(accountId?: string): void {
    // this is so that the decimal value is excluded from the step number, since this is the last sub-step.
    let subIncrement = round(this.stepNumber + 0.1, 1).toFixed(0);

    const avgCard = this.processingVolumesForm.value.averageTransactionAmountCard;
    const avgDebit = this.processingVolumesForm.value.averageTransactionAmountDebit;
    const avgMonthly = this.processingVolumesForm.value.averageMonthlyVolume;

    this.processingVolumesForm.markAllAsTouched();

    if (this.processingVolumesForm.invalid) {
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    if (!this.merchantApp.legal_entity.processing_volume) {
      this.merchantApp.legal_entity.processing_volume = {} as ProcessingVolume;
    }

    if (this.hasCard) {
      if (avgCard >= avgMonthly) {
        this.reviewProcessingVolumes();
        return;
      }
      this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card = this.processingVolumesForm.value
        .averageTransactionAmountCard
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountCard)
        : null;
    }
    if (this.hasDebit) {
      if (avgDebit >= avgMonthly) {
        this.reviewProcessingVolumes();
        return;
      }
      this.merchantApp.legal_entity.processing_volume.average_transaction_amount_debit = this.processingVolumesForm
        .value.averageTransactionAmountDebit
        ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageTransactionAmountDebit)
        : null;
    }
    if (!this.hasCard && !this.hasDebit && !environment.production) {
      //no pricing templates, set default value
      this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card = 1;
    }

    this.merchantApp.legal_entity.processing_volume.monthly_transaction_count =
      this.processingVolumesForm.value.averageMonthlyTransactions;
    this.merchantApp.legal_entity.processing_volume.monthly_processing_volume = this.processingVolumesForm.value
      .averageMonthlyVolume
      ? this._numericToMinorPipe.transform(this.processingVolumesForm.value.averageMonthlyVolume)
      : null;

    this._merchantAppService.updateMerchantApplication(this.merchantApp, parseFloat(subIncrement), accountId);
  }

  reviewProcessingVolumes(): void {
    this.reviewAlert = {
      title: 'Review processing volumes',
      message:
        'The average transaction amount cannot be higher than or equal to your monthly processing volume. \n\nPlease review and correct these values.',
      order: null,
      type: null,
    };
    let elementsToFocus = [document.querySelector('tilled-input[name="averageMonthlyVolume"] mat-form-field')];
    this.scrollToTop();
    if (this.hasCard) {
      elementsToFocus.push(document.querySelector('tilled-input[name="averageTransactionAmountCard"] mat-form-field'));
    }
    if (this.hasDebit) {
      elementsToFocus.push(document.querySelector('tilled-input[name="averageTransactionAmountDebit"] mat-form-field'));
    }
    elementsToFocus.forEach((el) => {
      el?.classList.add('mat-focused');
    });
  }

  private isAppUnsaved(): boolean {
    for (const field in this.processingVolumesForm.controls) {
      const control = this.processingVolumesForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    return !(this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_card
      ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_card / 100 ==
        this.processingVolumesForm.value.averageTransactionAmountCard
      : null == this.processingVolumesForm.value.averageTransactionAmountCard &&
          this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_debit
        ? this.merchantApp.legal_entity.processing_volume.average_transaction_amount_debit / 100 ==
          this.processingVolumesForm.value.averageTransactionAmountDebit
        : null == this.processingVolumesForm.value.averageTransactionAmountDebit &&
            this.merchantApp.legal_entity?.processing_volume?.monthly_processing_volume
          ? this.merchantApp.legal_entity.processing_volume.monthly_processing_volume / 100 ==
            this.processingVolumesForm.value.averageMonthlyVolume
          : null == this.processingVolumesForm.value.averageMonthlyVolume &&
            this.merchantApp.legal_entity?.processing_volume?.monthly_transaction_count ==
              this.processingVolumesForm.value.averageMonthlyTransactions);
  }

  private resetApplication(): void {
    this.processingVolumesForm.controls['averageMonthlyTransactions'].setValue(
      this.merchantApp.legal_entity?.processing_volume?.monthly_transaction_count,
    );

    this.processingVolumesForm.controls['averageMonthlyVolume'].setValue(
      this.merchantApp.legal_entity?.processing_volume?.monthly_processing_volume
        ? this.merchantApp.legal_entity?.processing_volume?.monthly_processing_volume / 100
        : null,
    );

    const cardPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
        )
      : null;
    const cardPresentPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT,
        )
      : null;
    this.hasCard = cardPricing || cardPresentPricing ? true : false;
    if (this.hasCard) {
      this.processingVolumesForm.controls['averageTransactionAmountCard'].setValue(
        this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_card
          ? this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_card / 100
          : null,
      );
    }

    const debitPricing = this.merchantApp?.pricing_templates
      ? this.merchantApp.pricing_templates.find(
          (p) =>
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
        )
      : null;
    this.hasDebit = debitPricing ? true : false;
    if (this.hasDebit) {
      this.processingVolumesForm.controls['averageTransactionAmountDebit'].setValue(
        this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_debit
          ? this.merchantApp.legal_entity?.processing_volume?.average_transaction_amount_debit / 100
          : null,
      );
    }
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}
