<!-- we could probably do something clever-er here with isLoading -->
<div [hidden]="isLoading">
  <div
    [hidden]="dataLength === 0"
    [class]="scrollable && displayedColumns?.length > 6 && !viewportMaxSize ? 'no-scrollbar overflow-x-scroll' : ''"
  >
    <table mat-table [dataSource]="data" matSort (matSortChange)="sortData($event)" class="w-full border-spacing-2">
      <ng-container [matColumnDef]="column.key" *ngFor="let column of columns">
        <ng-container *ngIf="column.canSort; else noSort">
          <ng-container *ngIf="!column.headerTooltip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.key }}" [style]="column.styling ?? null">
              {{ column.name.toUpperCase() }}
            </th>
          </ng-container>
          <ng-container *ngIf="column.headerTooltip">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="{{ column.key }}"
              class="leading-10"
              [style]="column.styling ?? null"
            >
              {{ column.name.toUpperCase() }}
              <span
                [ngxTippy]="headerTooltipTemplate"
                tippyClassName="bg-tilled-primary"
                [tippyProps]="{
                  placement: 'right',
                  arrow: false,
                  interactive: true
                }"
              >
                <mat-icon
                  class="h-1 w-1 translate-y-[7px] scale-75 text-[4px] text-tilled-primary"
                  svgIcon="heroicons_solid:question-mark-circle"
                >
                </mat-icon>
              </span>
            </th>
          </ng-container>
        </ng-container>
        <ng-template #noSort>
          <ng-container *ngIf="!column.headerTooltip">
            <th mat-header-cell *matHeaderCellDef [style]="column.styling ?? null">
              {{ column.name ? column.name.toUpperCase() : '' }}
            </th>
          </ng-container>
          <ng-container *ngIf="column.headerTooltip">
            <th mat-header-cell *matHeaderCellDef class="leading-10" [style]="column.styling ?? null">
              {{ column.name ? column.name.toUpperCase() : '' }}
              <span
                [ngxTippy]="headerTooltipTemplate"
                tippyClassName="bg-tilled-primary"
                [tippyProps]="{
                  placement: 'right',
                  arrow: false,
                  interactive: true
                }"
              >
                <mat-icon
                  class="h-1 w-1 translate-y-[7px] scale-75 text-[4px] text-tilled-primary"
                  svgIcon="heroicons_solid:question-mark-circle"
                >
                </mat-icon>
              </span>
            </th>
          </ng-container>
        </ng-template>

        <ng-template #headerTooltipTemplate>
          <tilled-paragraph-p4 [white]="true" class="font-normal">
            <markdown [data]="column.headerTooltip"></markdown>
          </tilled-paragraph-p4>
        </ng-template>

        <ng-container *ngIf="column.isProgress; else second">
          <td class="h-12" mat-cell *matCellDef="let element" [style]="column.styling ?? null">
            <div class="flex">
              <tilled-progress-bar class="translate-y-2 pr-2" [value]="element[column.key]"></tilled-progress-bar>
              {{ element[column.key] }}%
            </div>
          </td>
        </ng-container>
        <ng-template #second>
          <ng-container *ngIf="column.isShareLink; else third">
            <td
              class="h-12"
              mat-cell
              *matCellDef="let element"
              class="justify-items-end pr-8"
              [style]="column.styling ?? null"
            >
              <ng-container *ngIf="element['accountIdForInvitation']">
                <button
                  mat-icon-button
                  (click)="inviteNewMerchantUser(element['accountIdForInvitation'], element['name'])"
                >
                  <mat-icon class="text-primary">share</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </ng-template>

        <ng-template #third>
          <ng-container *ngIf="column.isChip; else forth">
            <td class="h-12" mat-cell *matCellDef="let element" [style]="column.styling ?? null">
              <tilled-chip [chipConfig]="element[column.chipConfig]"></tilled-chip>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #forth>
          <ng-container *ngIf="column.isAction; else fifth">
            <td
              class="h-12"
              mat-cell
              *matCellDef="let element"
              [style]="column.styling ?? null"
              (mouseover)="actionHover = true"
              (mouseout)="actionHover = false"
            >
              <div
                class="absolute right-0 -translate-y-[50%] bg-white pr-8"
                *ngIf="!column.isActionable || column.isActionable(element)"
              >
                <button mat-icon-button (click)="actionClicked(element)">
                  <mat-icon class="text-primary">chevron_right</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
        </ng-template>

        <ng-template #fifth>
          <ng-container *ngIf="column.isMultiline; else sixth">
            <td class="h-12" mat-cell *matCellDef="let element">
              <div class="items-left flex flex-col">
                <tilled-paragraph-p3 *ngIf="element[column.key] && element[column.key][0]" class="font-medium">{{
                  element[column.key][0]
                }}</tilled-paragraph-p3>
                <tilled-paragraph-p3 *ngIf="element[column.key] && element[column.key][1]" class="">{{
                  element[column.key][1]
                }}</tilled-paragraph-p3>
              </div>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #sixth>
          <ng-container *ngIf="column.isActionList; else seventh">
            <td class="h-12" mat-cell *matCellDef="let element">
              <div class="bg-tilled-neutral-100">
                <action-list-component [actionList]="element[column.key]"></action-list-component>
              </div>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #seventh>
          <ng-container *ngIf="column.isCurrency; else eighth">
            <td class="h-12" mat-cell *matCellDef="let element" [style]="column.styling ?? null">
              {{ element[column.key] | minorUnitsToCurrency: element['currency'] }}
            </td>
          </ng-container>
        </ng-template>

        <ng-template #eighth>
          <ng-container *ngIf="column.isDownload; else ninth">
            <td
              class="h-12"
              mat-cell
              *matCellDef="let element"
              class="justify-items-end"
              [style]="column.styling ?? null"
            >
              <ng-container *ngIf="element[column.key]">
                <button mat-icon-button (click)="downloadFileClicked(element['file_id'], element['file_name'])">
                  <mat-icon class="text-primary" [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
        </ng-template>

        <ng-template #ninth>
          <ng-container *ngIf="column.isImage; else dateTooltipCell">
            <td class="h-12" mat-cell *matCellDef="let element" [style]="column.styling ?? null">
              <ng-container *ngIf="element[column.key]">
                <img [style]="column.imageStyling ?? null" src="{{ element[column.key] }}" />
              </ng-container>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #dateTooltipCell>
          <ng-container *ngIf="column.dateTooltip; else tooltipCell">
            <td mat-cell *matCellDef="let element" [style]="column.styling ?? null">
              <date-tooltip
                *ngIf="element[column.key] !== (null || '')"
                [date]="element[column.key]"
                [tableCell]="true"
                [includeTime]="true"
              >
                {{ element[column.key] }}
              </date-tooltip>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #tooltipCell>
          <ng-container *ngIf="column.cellTooltip; else normalCell">
            <td
              class="h-12"
              mat-cell
              *matCellDef="let element"
              [style]="column.styling ?? null"
              [matTooltip]="column.showCellTooltipIcon ? null : column.cellTooltip(element[column.key], element)"
              matTooltipClass="'mat-tooltip-linebreak'"
            >
              {{ element[column.key] }}

              <mat-icon
                *ngIf="column.showCellTooltipIcon && column.cellTooltip(element[column.key], element)"
                class="icon-size-4"
                style="vertical-align: -2px"
                [svgIcon]="'mat_outline:help_outline'"
                [matTooltip]="column.cellTooltip(element[column.key], element)"
                matTooltipClass="'mat-tooltip-linebreak'"
              ></mat-icon>
            </td> </ng-container
        ></ng-template>
        <ng-template #normalCell>
          <ng-container *ngIf="column.propertyType === 'Number'; else leftAlign">
            <td
              mat-cell
              *matCellDef="let row"
              [style]="column.styling ?? null"
              class="text-overflow-ellipsis h-12"
              nowrap
              [isOverflowing]
              #c="isOverflowing"
              [matTooltip]="c.isOverflowing ? row[column.key] : null"
              [matTooltipClass]="'mat-tooltip-linebreak'"
              dashedEmptyCell
            >
              {{ row[column.key] }}
            </td>
          </ng-container>
          <ng-template #leftAlign>
            <td
              mat-cell
              *matCellDef="let row"
              [style]="column.styling ?? null"
              class="text-overflow-ellipsis h-12"
              nowrap
              [isOverflowing]
              #c="isOverflowing"
              [matTooltip]="c.isOverflowing ? row[column.key] : null"
              [matTooltipClass]="'mat-tooltip-linebreak'"
              dashedEmptyCell
            >
              {{ row[column.key] }}
            </td>
          </ng-template>
        </ng-template>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <ng-container *ngIf="getClickCallback; else noClick">
        <tr
          mat-row
          class="cursor-pointer"
          *matRowDef="let row; columns: displayedColumns"
          (click)="rowClicked(row, $event)"
        ></tr>
      </ng-container>
      <ng-template #noClick>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </ng-template>
      <div class="flex w-full content-center justify-center" *matNoDataRow>
        <div class="flex flex-col items-center">
          <tilled-heading-h2 *ngIf="dataLength === 0" class="mb-4 mt-8">{{ noDataMainText }}</tilled-heading-h2>
          <tilled-paragraph-p1 *ngIf="dataLength === 0" class="mb-8">{{ noDataSecondaryText }}</tilled-paragraph-p1>
        </div>
      </div>
    </table>
  </div>
  <div class="flex flex-row-reverse justify-between">
    <div [hidden]="hidePaginator || dataLength < pageSize">
      <mat-paginator
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        showFirstLastButtons
        [length]="dataLength"
      >
      </mat-paginator>
    </div>
    <div class="flex min-w-[40%] justify-around" *ngIf="showDisplayedColumnsDropdown">
      <div class="flex flex-col justify-center">
        <tilled-paragraph-p4 secondary="true">Displayed Columns: </tilled-paragraph-p4>
      </div>
      <div class="flex min-w-[60%] flex-col justify-center rounded-sm pt-3">
        <mat-form-field appearence="fill">
          <mat-select
            placeholder="Displayed Columns"
            multiple
            (selectionChange)="onColumnSelectionChange($event)"
            [value]="displayedColumns"
          >
            <ng-container *ngFor="let column of columns">
              <mat-option *ngIf="column.name" [value]="column.key">{{ column.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div [hidden]="hideZeroState || dataLength > 0">
    <div class="my-[10%] text-center tracking-tight">
      <div class="mx-4 mt-4 text-2xl font-bold">
        {{ noDataMainText }}
      </div>
      <div class="mx-4 mb-4 mt-2">{{ noDataSecondaryText }}</div>
    </div>
  </div>
</div>
