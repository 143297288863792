/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountInternalMetadata { 
    bank_verification_methods?: Array<AccountInternalMetadata.BankVerificationMethodsEnum>;
    card_updater?: boolean;
    hubspot_company_record_id?: string;
    hubspot_deal_record_id?: string;
    is_saas_account?: string;
    merchant_of_record?: string;
    pci_aoc_expires_at?: string;
    pci_aoc_submitted?: string;
    status?: AccountInternalMetadata.StatusEnum;
    tilled_underwriting?: boolean;
}
export namespace AccountInternalMetadata {
    export type BankVerificationMethodsEnum = 'plaid' | 'paysafe' | 'tilled_manual';
    export const BankVerificationMethodsEnum = {
        PLAID: 'plaid' as BankVerificationMethodsEnum,
        PAYSAFE: 'paysafe' as BankVerificationMethodsEnum,
        TILLED_MANUAL: 'tilled_manual' as BankVerificationMethodsEnum
    };
    export type StatusEnum = 'certified' | 'active' | 'live' | 'disabled' | 'lead' | 'integration';
    export const StatusEnum = {
        CERTIFIED: 'certified' as StatusEnum,
        ACTIVE: 'active' as StatusEnum,
        LIVE: 'live' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum,
        LEAD: 'lead' as StatusEnum,
        INTEGRATION: 'integration' as StatusEnum
    };
}


