import { CommonModule, KeyValue } from '@angular/common';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { ComponentBase } from 'app/core/componentBase';
import { PaymentsTypes } from 'app/core/data/payments-types';
import { SelectorTypes } from 'app/core/data/selector-types';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { SentenceCasePipe } from 'app/core/pipes/sentence-case.pipe';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { PaymentAppService } from 'app/core/services/payments.app.service';
import { TerminalReadersAppService } from 'app/core/services/terminal-readers.app.service';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { allOrNone } from 'app/shared/validators/all-or-none.validator';
import { environment } from 'environments/environment';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import {
  Account,
  AccountCapability,
  CreatePaymentIntentRequestParams,
  InternalTerminalReader,
  Level3,
  LineItem,
  PaymentIntentCreateParams,
  PaymentMethod,
  PaymentMethodCreateParams,
  TerminalReader,
} from '../../../../../projects/tilled-api-client/src';
import { FormCardComponent } from '../../cards/form-cards/form-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { LineItemsComponent } from '../../line-items-component.ts/line-items.component';
import { MerchantSelectorComponent } from '../../merchant-selector/merchant-selector.component';
import { TilledHeadingH2Component } from '../../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledHeadingH5Component } from '../../tilled-text/tilled-heading/tilled-heading-h5.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

declare let Tilled: any; // from /assets/js/tilled.js
declare let Payments: any; // from /assets/js/payments.js
@Component({
  selector: 'app-collect-payment',
  templateUrl: './collect-payment-dialog.component.html',
  styleUrls: ['./collect-payment-dialog.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    MatStepperModule,
    FormCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    MerchantSelectorComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    TilledInputComponent,
    MatRadioModule,
    MatIconModule,
    MatSlideToggleModule,
    TilledParagraphP4Component,
    TilledHeadingH5Component,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    LineItemsComponent,
    MinorUnitsToCurrencyPipe,
    CommonModule,
    SentenceCasePipe,
  ],
})
export class CollectPaymentDialogComponent extends ComponentBase implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  public isMerchant: boolean;
  public account$: Observable<Account>;
  public merchantAccount: Account;
  public newPaymentForm: FormGroup;
  public availablePaymentMethods: Map<string, string>;
  public availableCurrencies: { label: string; value: string }[];
  public availableTerminals: { label: string; value: string }[];
  public cardPaymentForm: FormGroup;
  public bankPaymentForm: FormGroup;
  public eftPaymentForm: FormGroup;
  public selectedPaymentType: PaymentMethod.TypeEnum;
  public selectedTerminalReaderId: string;
  public terminal$: Observable<InternalTerminalReader>;
  public terminal: InternalTerminalReader = null;
  public terminalIds: TerminalReader[];
  public captureMethod: string;
  public selectedPaymentMethod: string;
  public selectedCurrency: string;
  public disableCard: boolean = false;
  public disableAch: boolean = false;
  public disableEft: boolean = false;
  public disableCurrency: boolean = true;
  public disablePaymentMethod: boolean = false;
  public cardAddressIsChecked: boolean = false;
  public cardLevel3IsChecked: boolean = false;
  public lineItems: LineItem[];
  public countryCodeMap: { label: string; value: string }[];
  public stateCodeMap: { label: string; value: string }[];
  public tilledCardForm: any;
  public tilledBankForm: any;
  public showCardNumberError = false;
  public showExpirationError = false;
  public showCvvError = false;
  public showAccountNumberError = false;
  public showRoutingNumberError = false;
  public errorTitle: string;
  public errorMessage: string;
  public selectDevicePlaceholder: string = 'Select a terminal';
  private isWhiteLabel: boolean;

  private accountId: string;
  private tilledCard: any;
  private tilledBank: any;
  private cardFormBuilt = false;
  private bankFormBuilt = false;

  private _alertDialogError$ = new Subject<boolean>();
  public alertDialogError$ = this._alertDialogError$.asObservable();

  private _submittingPayment$ = new Subject<boolean>();
  public submittingPayment$ = this._submittingPayment$.asObservable();

  constructor(
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<CollectPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _paymentAppService: PaymentAppService,
    private _accountAppService: AccountAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _brandingService: BrandingService,
    private _terminalReadersAppService: TerminalReadersAppService,
  ) {
    super();
    this._brandingService.isWhiteLabel$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isWhiteLabel) => {
      this.isWhiteLabel = isWhiteLabel;
      this.loadTilledJs();
    });
  }

  ngOnInit(): void {
    this.accountId = this._data?.accountId ?? null;
    this.isMerchant = this._data?.isMerchant ?? false;
    this.terminal$ = this._terminalReadersAppService.terminalReader$;
    this.terminal$.subscribe({
      next: (terminal) => {
        if (terminal) {
          this.terminal = terminal;
        }
      },
    });
    this.countryCodeMap = Array.from(SelectorTypes.CountryToCode).map(([label, value]) => ({ label, value }));
    this.stateCodeMap = Array.from(SelectorTypes.stateAndProvinceMap).map(([value, label]) => ({ value, label }));

    if (this.isMerchant) {
      this.account$ = this._accountAppService.connectedAccount$;
      this._accountAppService.getConnectedAccountById(this.accountId);
      this.account$.subscribe({
        next: (result) => {
          this.merchantAccountChanged(result);
        },
        error: (err) => {},
      });
    }

    this.newPaymentForm = this._formBuilder.group({
      paymentMethod: new FormControl<string | null>(this.selectedPaymentMethod ?? null, [Validators.required]),
      currency: new FormControl<string | null>(this.selectedCurrency ?? null, [Validators.required]),
      amount: new FormControl<number | null>(null, [Validators.required, Validators.min(0.01)]),
      terminalIds: new FormControl<string | null>(this.selectedTerminalReaderId ?? null),
      captureMethod: new FormControl<string | null>('automatic'),
    });

    this.newPaymentForm
      .get('terminalIds')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((terminalId) => {
        if (terminalId) {
          this.selectedTerminalReaderId = terminalId;
          this.updateTerminalInfo();
        } else {
          this.terminal = null;
          this.selectedTerminalReaderId = null;
        }
      });

    this.cardPaymentForm = this._formBuilder.group({
      cardholderName: new FormControl<string | null>(null, [Validators.required]),
      postalCode: new FormControl<string | null>(null, [Validators.required]),
      street1: new FormControl<string | null>(null),
      street2: new FormControl<string | null>(null),
      country: new FormControl<string | null>(null),
      state: new FormControl<string | null>(null),
      city: new FormControl<string | null>(null),
      level3Form: new FormGroup(
        {
          shipToCountry: new FormControl<string | null>(null),
          shipToZip: new FormControl<string | null>(null),
          shipFromZip: new FormControl<string | null>(null),
          shipAmount: new FormControl<number | null>(null),
          dutyAmount: new FormControl<number | null>(null),
          lineItemsArray: this._formBuilder.array([]),
        },
        [allOrNone()],
      ),
    });
    this.bankPaymentForm = this._formBuilder.group({
      accountholderName: new FormControl<string | null>(null, [Validators.required]),
      accountType: new FormControl<string | null>(null, [Validators.required]),
      street1: new FormControl<string | null>(null, [Validators.required]),
      street2: new FormControl<string | null>(null),
      country: new FormControl<string | null>(null, [Validators.required]),
      state: new FormControl<string | null>(null, [Validators.required]),
      city: new FormControl<string | null>(null, [Validators.required]),
      postalCode: new FormControl<string | null>(null, [Validators.required]),
    });
  }

  loadTilledJs(): void {
    const script = document.createElement('script');
    const hostname = window.location.hostname;
    if (
      window.location.hostname.includes('staging-app.tilled.com') ||
      window.location.hostname.includes('staging-paymentsonline.io') ||
      window.location.hostname.includes('amplifyapp.com')
    ) {
      if (this.isWhiteLabel) {
        script.src = 'https://js.staging-paymentsonline.io/v2';
      } else {
        script.src = 'https://staging-js.tilled.com/v2';
      }
    } else if (window.location.hostname.includes('localhost') || hostname.includes('ngrok')) {
      if (this.isWhiteLabel) {
        script.src = './assets/js/payments.js';
      } else {
        script.src = './assets/js/tilled.js';
      }
    } else {
      if (this.isWhiteLabel) {
        script.src = 'https://js.paymentsonline.io/v2';
      } else {
        script.src = 'https://js.tilled.com/v2';
      }
    }
    document.head.appendChild(script);
  }

  updateLineItems = (lineItemsArray: LineItem[]): void => {
    this.lineItems = lineItemsArray;

    const formArray = this.cardPaymentForm.get('level3Form').get('lineItemsArray') as FormArray;
    formArray.clear();
    if (lineItemsArray) {
      for (const lineItem of lineItemsArray) {
        formArray.push(
          new FormControl({
            lineItem: [
              {
                value: lineItem,
                disabled: false, // disable input if k is passed in
              },
            ],
          }),
        );
      }
    }
  };

  merchantAccountChanged(account: Account): void {
    if (!account?.id) {
      return;
    }

    this.merchantAccount = account;
    this.terminalIds = this.merchantAccount.terminal_readers;

    this.availableTerminals = this.terminalIds.map((terminal) => ({
      label: terminal.description ? terminal.description : terminal.id,
      value: terminal.id,
    }));
    this.availablePaymentMethods = new Map<string, string>();
    this.availableCurrencies = new Array<{ label: string; value: string }>();
    for (let capability of this.merchantAccount.capabilities) {
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        this.availablePaymentMethods.set(
          capability?.product_code?.payment_method_type,
          PaymentsTypes.PaymentMethodDisplayText.get(capability?.product_code?.payment_method_type),
        );
      }
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        if (capability?.product_code?.currency === this.availableCurrencies[0]?.value) {
          this.availableCurrencies = [
            {
              label: capability?.product_code?.currency.toUpperCase(),
              value: capability?.product_code?.currency,
            },
          ];
        } else {
          this.availableCurrencies.push({
            label: capability?.product_code?.currency.toUpperCase(),
            value: capability?.product_code?.currency,
          });
        }
      }
    }

    if (this.availablePaymentMethods.size === 1) {
      this.selectedPaymentMethod = Array.from(this.availablePaymentMethods.keys())[0];
      if (this.newPaymentForm) {
        this.newPaymentForm.get('paymentMethod').setValue(this.selectedPaymentMethod);
      }
      this.disablePaymentMethod = true;
    } else {
      this.selectedPaymentMethod = null;
      this.disablePaymentMethod = false;

      if (this.availablePaymentMethods.has('card')) {
        this.selectedPaymentMethod = PaymentMethod.TypeEnum.CARD;
      }
    }

    if (this.availableCurrencies.length === 1) {
      this.selectedCurrency = this.availableCurrencies[0].value;
      this.newPaymentForm.get('currency').setValue(this.selectedCurrency);
      this.disableCurrency = true;
    } else {
      this.selectedCurrency = null;
      this.disableCurrency = false;
    }

    if (this.availableTerminals?.length === 1) {
      const selectedTerminal = this.availableTerminals[0];
      this.selectedTerminalReaderId = selectedTerminal.value;
      this.updateTerminalInfo();
      this.newPaymentForm.get('terminalIds').setValue(this.selectedTerminalReaderId);
      this.selectDevicePlaceholder = selectedTerminal.label;
    }
  }

  // these are optional for credit card
  cardAddressToggled(event: MatSlideToggleChange): void {
    if (!event.checked) {
      this.cardPaymentForm.get('street1').setValue(null);
      this.cardPaymentForm.get('street2').setValue(null);
      this.cardPaymentForm.get('country').setValue(null);
      this.cardPaymentForm.get('state').setValue(null);
      this.cardPaymentForm.get('city').setValue(null);
    }
  }

  cardLevel3Toggled(event: MatSlideToggleChange): void {
    if (!event.checked) {
      this.lineItems = null;
      this.cardPaymentForm.get('level3Form').reset();
    }
  }

  public async closeDialog(value?: any): Promise<void> {
    if (this.tilledCardForm) {
      await this.tilledCardForm.teardown();
    }
    if (this.tilledBankForm) {
      await this.tilledBankForm.teardown();
    }

    this.ngZone.run(() => {
      this.dialogRef.close(value);
    });
  }

  public async nextPage(): Promise<void> {
    this.selectedPaymentType = this.newPaymentForm.get('paymentMethod').value;
    this.selectedCurrency = this.newPaymentForm.get('currency').value;

    if (this.selectedPaymentType == PaymentMethod.TypeEnum.EFT_DEBIT) {
      this.bankPaymentForm.get('accountType').clearValidators();
      this.bankPaymentForm.get('accountType').updateValueAndValidity();
    }

    this.stepper.next();
    if (!this.cardFormBuilt && this.selectedPaymentType == PaymentMethod.TypeEnum.CARD) {
      this.cardFormBuilt = true;
      await this.buildTilledCardForm();
    }
    if (
      !this.bankFormBuilt &&
      (this.selectedPaymentType == PaymentMethod.TypeEnum.ACH_DEBIT ||
        this.selectedPaymentType == PaymentMethod.TypeEnum.EFT_DEBIT)
    ) {
      this.bankFormBuilt = true;
      await this.buildTilledBankForm();
    }
  }

  public prevPage(): void {
    this.selectedPaymentType = null;
    this.stepper.previous();
  }

  public async collectBankPayment(): Promise<void> {
    this._submittingPayment$.next(true);
    await this.tilledBank
      .createPaymentMethod({
        type:
          this.selectedPaymentType === PaymentMethod.TypeEnum.ACH_DEBIT
            ? PaymentMethodCreateParams.TypeEnum.ACH_DEBIT
            : PaymentMethodCreateParams.TypeEnum.EFT_DEBIT,
        ach_debit: {
          account_type:
            this.selectedPaymentType === PaymentMethod.TypeEnum.ACH_DEBIT
              ? this.bankPaymentForm.get('accountType').value
              : null,
          account_holder_name: this.bankPaymentForm.get('accountholderName').value,
        },
        billing_details: {
          address: {
            street: this.bankPaymentForm.get('street1').value,
            street2: this.bankPaymentForm.get('street2').value,
            country: this.bankPaymentForm.get('country').value,
            state: this.bankPaymentForm.get('state').value,
            city: this.bankPaymentForm.get('city').value,
            zip: this.bankPaymentForm.get('postalCode').value,
          },
          name: this.bankPaymentForm.get('accountholderName').value,
        },
      })
      .then(
        async (paymentMethod) => {
          await this.createPaymentIntent(paymentMethod);
        },
        (err) => {
          // An error with the request (>400 status code)
          this.errorTitle = 'Creating Payment Method Failed';
          if (err === 'Unauthorized: jwt expired') {
            this.errorMessage = 'Please close this payment form and try again.';
          } else if (err.toString().includes('billing_details.address.state')) {
            if (this.bankPaymentForm.get('country').value == 'US') {
              this.errorMessage = 'Please select a valid state';
            } else {
              this.errorMessage = 'Please select a valid province';
            }
          } else {
            this.errorMessage = err;
          }
          this._alertDialogError$.next(true);
          this._submittingPayment$.next(false);
        },
      );
  }

  public async collectCardPresentPayment(): Promise<void> {
    this._submittingPayment$.next(true);
    this.selectedTerminalReaderId = this.newPaymentForm.get('terminalIds').value;
    this._paymentAppService.payment$.pipe(takeUntil(this._unsubscribeAll)).subscribe((payment) => {
      this.closeDialog(payment.id);
    });

    this.captureMethod = this.newPaymentForm.get('captureMethod').value;

    await this._paymentAppService.createCardPresentPayment(
      {
        tilledAccount: this.merchantAccount.id,
        paymentMethodCreateParams: {
          type: PaymentMethodCreateParams.TypeEnum.CARD_PRESENT,
          terminal_reader_id: this.selectedTerminalReaderId,
        },
      },
      this._numericToMinorPipe.transform(this.newPaymentForm.get('amount').value),
      this.captureMethod,
    );
  }

  public async collectCardPayment(): Promise<void> {
    this._submittingPayment$.next(true);
    await this.tilledCard
      .createPaymentMethod({
        type: PaymentMethodCreateParams.TypeEnum.CARD,
        billing_details: {
          address: {
            street: this.cardPaymentForm.get('street1').value,
            street2: this.cardPaymentForm.get('street2').value,
            country: this.cardPaymentForm.get('country').value,
            state: this.cardPaymentForm.get('state').value,
            city: this.cardPaymentForm.get('city').value,
            zip: this.cardPaymentForm.get('postalCode').value,
          },
          name: this.cardPaymentForm.get('cardholderName').value,
        },
      })
      .then(
        async (paymentMethod) => {
          const level3 = this.prepareCardPayment();

          await this.createPaymentIntent(paymentMethod, level3);
        },
        (err) => {
          // An error with the request (>400 status code)
          this.errorTitle = 'Creating Payment Method Failed';
          if (err === 'Unauthorized: jwt expired') {
            this.errorMessage = 'Please close this payment form and try again.';
          } else if (err.toString().includes('billing_details.address.state')) {
            if (this.cardPaymentForm.get('country').value == 'US') {
              this.errorMessage = 'Please select a valid state';
            } else {
              this.errorMessage = 'Please select a valid province';
            }
          } else {
            this.errorMessage = err;
          }
          this._alertDialogError$.next(true);
          this._submittingPayment$.next(false);
        },
      );
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => 0;

  private async buildTilledCardForm(): Promise<void> {
    const publishableKey = AuthService.getAccessToken();

    if (this.isWhiteLabel) {
      this.tilledCard = new Payments(publishableKey, this.merchantAccount.id, {
        endpoint: environment.whiteLabelApi + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    } else {
      this.tilledCard = new Tilled(publishableKey, this.merchantAccount.id, {
        endpoint: environment.api + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    }

    this.tilledCardForm = await this.tilledCard.form({
      payment_method_type: PaymentMethod.TypeEnum.CARD,
    });

    const paymentRequest = this.tilledCard.paymentRequest({
      total: {
        label: this.merchantAccount.name,
        amount: this._numericToMinorPipe.transform(this.newPaymentForm.get('amount').value),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        const prButton = this.tilledCardForm.createField('paymentRequestButton', {
          paymentRequest: paymentRequest,
        });
        // Inject paymentRequestButton Form Field to the DOM
        prButton.inject('#native-payment-element');
      }
    });

    paymentRequest.on('paymentmethod', async (ev) => {
      const paymentMethod = ev.paymentMethod;
      try {
        await this.createPaymentIntent(paymentMethod);
        ev.complete('success');
      } catch {
        ev.complete('fail');
      }
    });

    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter var, sans-serif',
          color: '#1B253B', //fuse-accent (text-tilled-primary)
          fontWeight: '400',
          fontSize: '15px',
          '::placeholder': {
            color: '#94A3B8',
          },
        },
      },
    };

    this.tilledCardForm
      .createField('cardNumber', { ...fieldOptions, placeholder: '4111 1111 1111 1111' })
      .inject('#card-number-element');
    this.tilledCardForm
      .createField('cardExpiry', { ...fieldOptions, placeholder: '' })
      .inject('#card-expiration-element');
    this.tilledCardForm.createField('cardCvv', { ...fieldOptions, placeholder: '123' }).inject('#card-cvv-element');

    this.tilledCardForm.fields.cardNumber.on('change', (change) => {
      const cardBrand = change.brand;
      const cardSuffix: any = document.getElementById('card-suffix');

      switch (cardBrand) {
        case 'amex':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/amex.svg" />';
          break;
        case 'mastercard':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/mastercard.svg" />';
          break;
        case 'visa':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/visa.svg" />';
          break;
        case 'discover':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/discover.svg" />';
          break;
        case 'maestro':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/maestro.svg" />';
          break;
        case 'jcb':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/jcb.svg" />';
          break;
        case 'interac':
          cardSuffix.innerHTML = '<img src="assets/images/payment-methods/interac.svg" />';
          break;
        default:
          cardSuffix.innerHTML =
            '<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">credit_card</mat-icon>';
      }
      if (this.tilledCardForm.fields.cardNumber.valid) {
        this.tilledCardForm.fields.cardNumber.element.classList.remove('success');
        this.tilledCardForm.fields.cardNumber.element.classList.remove('error');
        this.showCardNumberError = false;
        this.tilledCardForm.fields.cardNumber.element.classList.add('success');
      }
    });

    this.tilledCardForm.fields.cardNumber.on('focus', () => {
      this.tilledCardForm.fields.cardNumber.element.classList.add('focus');
    });

    this.tilledCardForm.fields.cardNumber.on('blur', () => {
      this.tilledCardForm.fields.cardNumber.element.classList.remove('success');
      this.tilledCardForm.fields.cardNumber.element.classList.remove('error');
      if (this.tilledCardForm.fields.cardNumber.valid) {
        this.showCardNumberError = false;
        this.tilledCardForm.fields.cardNumber.element.classList.add('success');
      } else {
        this.showCardNumberError = true;
        this.tilledCardForm.fields.cardNumber.element.classList.add('error');
      }
      this.tilledCardForm.fields.cardNumber.element.classList.remove('focus');
    });

    this.tilledCardForm.fields.cardExpiry.on('change', (change) => {
      if (this.tilledCardForm.fields.cardExpiry.valid) {
        this.tilledCardForm.fields.cardExpiry.element.classList.remove('success');
        this.tilledCardForm.fields.cardExpiry.element.classList.remove('error');
        this.showExpirationError = false;
        this.tilledCardForm.fields.cardExpiry.element.classList.add('success');
      }
    });

    this.tilledCardForm.fields.cardExpiry.on('focus', () => {
      this.tilledCardForm.fields.cardExpiry.element.classList.add('focus');
    });

    this.tilledCardForm.fields.cardExpiry.on('blur', () => {
      this.tilledCardForm.fields.cardExpiry.element.classList.remove('success');
      this.tilledCardForm.fields.cardExpiry.element.classList.remove('error');
      if (this.tilledCardForm.fields.cardExpiry.valid) {
        this.showExpirationError = false;
        this.tilledCardForm.fields.cardExpiry.element.classList.add('success');
      } else {
        this.showExpirationError = true;
        this.tilledCardForm.fields.cardExpiry.element.classList.add('error');
      }
      this.tilledCardForm.fields.cardExpiry.element.classList.remove('focus');
    });

    this.tilledCardForm.fields.cardCvv.on('change', (change) => {
      if (this.tilledCardForm.fields.cardCvv.valid) {
        this.tilledCardForm.fields.cardCvv.element.classList.remove('success');
        this.tilledCardForm.fields.cardCvv.element.classList.remove('error');
        this.showCvvError = false;
        this.tilledCardForm.fields.cardCvv.element.classList.add('success');
      }
    });

    this.tilledCardForm.fields.cardCvv.on('focus', () => {
      this.tilledCardForm.fields.cardCvv.element.classList.add('focus');
    });

    this.tilledCardForm.fields.cardCvv.on('blur', () => {
      this.tilledCardForm.fields.cardCvv.element.classList.remove('success');
      this.tilledCardForm.fields.cardCvv.element.classList.remove('error');
      if (this.tilledCardForm.fields.cardCvv.valid) {
        this.showCvvError = false;
        this.tilledCardForm.fields.cardCvv.element.classList.add('success');
      } else {
        this.showCvvError = true;
        this.tilledCardForm.fields.cardCvv.element.classList.add('error');
      }
      this.tilledCardForm.fields.cardCvv.element.classList.remove('focus');
    });

    await this.tilledCardForm.build();
  }

  private async buildTilledBankForm(): Promise<void> {
    const publishableKey = AuthService.getAccessToken();

    if (this.isWhiteLabel) {
      this.tilledBank = new Payments(publishableKey, this.merchantAccount.id, {
        endpoint: environment.whiteLabelApi + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    } else {
      this.tilledBank = new Tilled(publishableKey, this.merchantAccount.id, {
        endpoint: environment.api + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    }

    this.tilledBankForm = await this.tilledBank.form({
      payment_method_type: this.selectedPaymentType,
    });

    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter var, sans-serif',
          color: '#1B253B', //fuse-accent (text-tilled-primary)
          fontWeight: '400',
          fontSize: '15px',
          '::placeholder': {
            color: '#94A3B8',
          },
        },
      },
    };

    this.tilledBankForm
      .createField('bankAccountNumber', { ...fieldOptions, placeholder: '1234567890' })
      .inject('#bank-account-number-element');
    this.tilledBankForm
      .createField('bankRoutingNumber', { ...fieldOptions, placeholder: '011103093' })
      .inject('#bank-routing-number-element');

    this.tilledBankForm.fields.bankAccountNumber.on('change', (change) => {
      if (this.tilledBankForm.fields.bankAccountNumber.valid) {
        this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('success');
        this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('error');
        this.showAccountNumberError = false;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('success');
      }
    });

    this.tilledBankForm.fields.bankAccountNumber.on('focus', () => {
      this.tilledBankForm.fields.bankAccountNumber.element.classList.add('focus');
    });

    this.tilledBankForm.fields.bankAccountNumber.on('blur', () => {
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('success');
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('error');
      if (this.tilledBankForm.fields.bankAccountNumber.valid) {
        this.showAccountNumberError = false;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('success');
      } else {
        this.showAccountNumberError = true;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('error');
      }
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('focus');
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('change', (change) => {
      if (this.tilledBankForm.fields.bankRoutingNumber.valid) {
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('success');
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('error');
        this.showRoutingNumberError = false;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('success');
      }
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('focus', () => {
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('focus');
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('blur', () => {
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('success');
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('error');
      if (this.tilledBankForm.fields.bankRoutingNumber.valid) {
        this.showRoutingNumberError = false;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('success');
      } else {
        this.showRoutingNumberError = true;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('error');
      }
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('focus');
    });

    await this.tilledBankForm.build();
  }

  private prepareCardPayment(): any {
    let lineItems: LineItem[] = null;
    const lineItemsArray = this.cardPaymentForm.get('level3Form').get('lineItemsArray').value;
    if (lineItemsArray && lineItemsArray.length > 0) {
      lineItems = [];
      for (const lineItemForm of lineItemsArray) {
        const lineItem = lineItemForm.lineItem[0].value;
        const tempLineItem: LineItem = {
          product_code: lineItem.product_code,
          product_description: lineItem.product_description,
          quantity: parseInt(lineItem.quantity),
          unit_amount: this._numericToMinorPipe.transform(lineItem.unit_amount),
          tax_amount: this._numericToMinorPipe.transform(lineItem.tax_amount),
        };
        lineItems.push(tempLineItem);
      }
    }

    let level3: Level3 = null;
    if (this.cardPaymentForm.get('level3Form').get('shipToZip').value) {
      level3 = {
        shipping_address_zip: this.cardPaymentForm.get('level3Form').get('shipToZip').value,
        shipping_address_country: this.cardPaymentForm.get('level3Form').get('shipToCountry').value,
        shipping_amount: this.cardPaymentForm.get('level3Form').get('shipAmount').value
          ? this._numericToMinorPipe.transform(this.cardPaymentForm.get('level3Form').get('shipAmount').value)
          : null,
        shipping_from_zip: this.cardPaymentForm.get('level3Form').get('shipFromZip').value,
        duty_amount: this.cardPaymentForm.get('level3Form').get('dutyAmount').value
          ? this._numericToMinorPipe.transform(this.cardPaymentForm.get('level3Form').get('dutyAmount').value)
          : null,
        line_items: lineItems,
      };
    }
    return level3;
  }

  private createPaymentIntent(paymentMethod: PaymentMethod, level3?: Level3): void {
    const createParams: CreatePaymentIntentRequestParams = {
      tilledAccount: this.merchantAccount.id,
      paymentIntentCreateParams: {
        payment_method_types: [this.selectedPaymentType],
        amount: this._numericToMinorPipe.transform(this.newPaymentForm.get('amount').value),
        currency: this.newPaymentForm.get('currency').value,
        payment_method_id: paymentMethod.id,
        capture_method: PaymentIntentCreateParams.CaptureMethodEnum.AUTOMATIC,
        level3: level3,
      },
    };

    const payment$ = this._paymentAppService.createPaymentIntent(createParams, this.accountId);
    const tilled = paymentMethod.type === PaymentMethod.TypeEnum.CARD ? this.tilledCard : this.tilledBank;

    payment$.subscribe({
      next: async (result) => {
        await tilled.confirmPayment(result.client_secret, { payment_method: paymentMethod.id }).then(
          (payment) => {
            if (payment.last_payment_error) {
              this.errorTitle = 'Payment Failed';
              this.errorMessage = payment.last_payment_error.message;
              this._alertDialogError$.next(true);
              this._submittingPayment$.next(false);
            } else {
              //payment flow is complete, close dialog
              this.closeDialog(payment.id);
            }
          },
          (err) => {
            this.errorTitle = 'Payment Failed';
            if (err === 'Unauthorized: jwt expired') {
              this.errorMessage = 'Please close this payment form and try again.';
            } else {
              this.errorMessage = err;
            }
            this._alertDialogError$.next(true);
            this._submittingPayment$.next(false);
          },
        );
      },
      error: (err) => {
        this.errorTitle = 'Payment Failed';
        this.errorMessage = err?.error?.message;
        this._alertDialogError$.next(true);
        this._submittingPayment$.next(false);
      },
    });
  }

  private updateTerminalInfo(): void {
    this._terminalReadersAppService.getTerminalReader({
      tilledAccount: this.merchantAccount.id,
      id: this.selectedTerminalReaderId,
    });
  }
}
