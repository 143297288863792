/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TerminalReaderSettings } from './terminalReaderSettings';


export interface TerminalReader { 
    /**
     * The Account that owns this terminal reader.
     */
    account_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The merchant provided description of the terminal reader
     */
    description?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * The serial number of the terminal reader.
     */
    serial_number?: string;
    settings?: TerminalReaderSettings;
    /**
     * The type of the terminal reader.
     */
    type: TerminalReader.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace TerminalReader {
    export type TypeEnum = 'vl_100' | 'vl_110' | 'vp_500';
    export const TypeEnum = {
        VL_100: 'vl_100' as TypeEnum,
        VL_110: 'vl_110' as TypeEnum,
        VP_500: 'vp_500' as TypeEnum
    };
}


