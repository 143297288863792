<form-card
  [submitButton]="true"
  [submitDisabled]="!updateTerminalReaderForm.get('serialNumber').value"
  [submitText]="'Update'"
  [titleText]="'Edit Terminal Reader'"
  [maxWidth]="'max-w-120'"
  (exitClick)="closeDialog()"
  (submitClick)="updateTerminalClicked()"
  [testIds]="{ exitButton: 'update-terminal-reader-close-Btn', submitButton: 'update-terminal-reader-save-Btn' }"
>
  <form
    class="flex w-full flex-col items-start space-y-2 rounded-md bg-white bg-opacity-10 outline outline-1 outline-white"
    [formGroup]="updateTerminalReaderForm"
  >
    <tilled-input
      class="w-full"
      name="description"
      tilledLabel="true"
      placeholder="Description"
      label="Description"
    ></tilled-input>
    <tilled-input
      class="w-full"
      name="serialNumber"
      tilledLabel="true"
      placeholder="125xxxxxx"
      label="Serial Number"
    ></tilled-input>
  </form>
</form-card>
