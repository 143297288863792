<form-card
  [titleText]="'Add Customer'"
  [secondaryText]="'Enter the customer\'s details.'"
  [submitButton]="true"
  [submitText]="'Add Customer'"
  [submitDisabled]="!customerForm.valid"
  (submitClick)="addCustomerClicked()"
  [exitButton]="true"
  (exitClick)="closeDialog()"
>
  <form [formGroup]="customerForm">
    <tilled-input
      name="first_name"
      data-test-id="customer-first-name"
      label="First name"
      [tilledLabel]="true"
      placeholder="Enter first name"
      [errors]="customerForm.get('first_name').errors && customerForm.get('first_name').touched"
      errorMessage="First name is required"
    ></tilled-input>
    <tilled-input
      name="middle_name"
      data-test-id="customer-middle-name"
      label="Middle name"
      [tilledLabel]="true"
      placeholder="Enter middle name"
      hint="Optional"
    ></tilled-input>
    <tilled-input
      name="last_name"
      data-test-id="customer-last-name"
      label="Last name"
      [tilledLabel]="true"
      placeholder="Enter last name"
      [errors]="customerForm.get('last_name').errors && customerForm.get('last_name').touched"
      errorMessage="Last name is required"
    ></tilled-input>
    <tilled-input
      name="email"
      data-test-id="customer-email"
      label="Email"
      [tilledLabel]="true"
      placeholder="Enter email"
      [errors]="customerForm.get('email').errors && customerForm.get('email').touched"
      errorMessage="Email invalid"
      hint="Optional"
    ></tilled-input>
    <tilled-input
      name="phone"
      data-test-id="customer-phone-number"
      label="Phone"
      [tilledLabel]="true"
      placeholder="Enter phone number"
      [errors]="customerForm.get('phone').errors && customerForm.get('phone').touched"
      errorMessage="Phone number invalid"
      hint="Optional"
    ></tilled-input>
  </form>
</form-card>
