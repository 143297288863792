<form-card
  [titleText]="'Add New Merchant'"
  [secondaryText]="
    'Enter the company’s basic information and select their pricing. A link will be generated to the application that can be shared with the merchant.'
  "
  (exitClick)="closeDialog()"
  [backButton]="false"
  [nextButton]="false"
  [submitDisabled]="connectedAccountForm.invalid"
  [submitButton]="true"
  [submitText]="'Add Merchant'"
  (submitClick)="addMerchantClicked()"
  [testIds]="{ submitButton: 'add-merchant-save-Btn' }"
  maxWidth="max-w-120"
>
  <!-- Form -->
  <ng-container>
    <div class="m-auto flex flex-col justify-center">
      <form [formGroup]="connectedAccountForm">
        <tilled-input
          data-test-id="merchant-account-owner"
          label="Business name"
          tilledLabel="true"
          name="name"
          [placeholder]="'Enter the business name'"
          [errors]="connectedAccountForm.get('name').hasError('required') && connectedAccountForm.get('name').touched"
          errorMessage="A business name is required"
        ></tilled-input>
        <div *ngIf="isSandbox" class="w-full pt-6">
          <fuse-alert
            [type]="'basic'"
            [appearance]="'outline'"
            [dismissible]="false"
            [dismissed]="false"
            [name]="'connectedAccountAlert'"
          >
            <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'mat_outline:help_outline'"> </mat-icon>
            <tilled-heading-h6 fuseAlertTitle>Sandbox Environment</tilled-heading-h6>
            <div class="flex flex-row">
              <tilled-paragraph-p3
                >In the sandbox, start the account name with "*" to automatically activate it (e.g. *The Surf
                Shop).</tilled-paragraph-p3
              >
            </div>
          </fuse-alert>
        </div>
        <tilled-input
          data-test-id="merchant-account-email"
          label="Business owner's email"
          tilledLabel="true"
          name="email"
          [placeholder]="'Enter the business owner\'s email'"
          [errors]="
            (connectedAccountForm.get('email').hasError('invalidEmail') ||
              connectedAccountForm.get('email').hasError('required')) &&
            connectedAccountForm.get('email').touched
          "
          errorMessage="A valid email is required"
        ></tilled-input>
        <div class="w-full pb-2.5 pt-4">
          <tilled-label-l1 secondary="true">Region</tilled-label-l1>
        </div>
        <tilled-select
          classOverride="w-full"
          data-test-id="merchant-account-region"
          [multiple]="false"
          [options]="[
            { label: 'United States', value: 'usd' },
            { label: 'Canada', value: 'cad' }
          ]"
          [controlName]="connectedAccountForm.get('region')"
          [placeholder]="'Select a region'"
          [errors]="
            connectedAccountForm.get('region').hasError('required') && connectedAccountForm.get('region').touched
          "
          errorMessage="A region is required"
          (selectionChange)="updatePricingTemplates($event)"
        ></tilled-select>
        <div class="flex w-full flex-col">
          <div *ngIf="hasCardCapability" class="pt-5">
            <mat-slide-toggle
              data-test-id="merchant-account-card-processing"
              color="primary"
              (change)="cardToggled($event)"
              [(ngModel)]="cardIsChecked"
              [ngModelOptions]="{ standalone: true }"
            >
              <tilled-paragraph-p3>Card-not-present processing</tilled-paragraph-p3>
            </mat-slide-toggle>
            <ng-container *ngIf="cardIsChecked">
              <div class="flex min-w-0 flex-auto flex-col">
                <tilled-select
                  [multiple]="false"
                  classOverride="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full my-4"
                  data-test-id="merchant-account-card-pricing"
                  [options]="cardPricingTemplateOptions"
                  [controlName]="connectedAccountForm.get('cardPricingTemplate')"
                  [placeholder]="'Select card pricing'"
                  [errors]="
                    connectedAccountForm.get('cardPricingTemplate').hasError('required') &&
                    connectedAccountForm.get('cardPricingTemplate').touched
                  "
                  [errorMessage]="cardErrorMessage"
                  (selectionChange)="updateSelectedCard($event)"
                ></tilled-select>
                <ng-container *ngIf="selectedCardTemplate" class="pt-6">
                  <card-pricing
                    [showCard]="true"
                    [currency]="selectedCardTemplate.currency"
                    [feeType]="selectedCardTemplate.fee_type"
                    [visaRate]="selectedCardTemplate.visa_rate"
                    [amexRate]="selectedCardTemplate.amex_rate"
                    [transactionFee]="selectedCardTemplate.transaction_fee"
                    [chargebackFee]="selectedCardTemplate.chargeback_fee"
                    [retrievalFee]="selectedCardTemplate.retrieval_fee"
                    [reversalFee]="selectedCardTemplate.reversal_fee"
                    [accountMonthlyFee]="selectedCardTemplate.account_monthly_fee"
                    [accountMonthlyMinimumFee]="selectedCardTemplate.account_monthly_minimum_fee"
                    [passThroughFees]="selectedCardTemplate.pass_through_fees"
                  ></card-pricing>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div *ngIf="hasCardPresentCapability" class="pt-5">
            <mat-slide-toggle
              data-test-id="merchant-account-card-present-processing"
              color="primary"
              (change)="cardPresentToggled($event)"
              [(ngModel)]="cardPresentIsChecked"
              [ngModelOptions]="{ standalone: true }"
            >
              <tilled-paragraph-p3>Card-present processing</tilled-paragraph-p3>
            </mat-slide-toggle>
            <ng-container *ngIf="cardPresentIsChecked">
              <div class="flex min-w-0 flex-auto flex-col">
                <tilled-select
                  [multiple]="false"
                  classOverride="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full my-4"
                  data-test-id="merchant-account-card-present-pricing"
                  [options]="cardPresentPricingTemplateOptions"
                  [controlName]="connectedAccountForm.get('cardPresentPricingTemplate')"
                  [placeholder]="'Select card present pricing'"
                  [errors]="
                    connectedAccountForm.get('cardPresentPricingTemplate').hasError('required') &&
                    this.connectedAccountForm.get('cardPresentPricingTemplate').touched
                  "
                  [errorMessage]="cardPresentErrorMessage"
                  (selectionChange)="updateSelectedCardPresent($event)"
                ></tilled-select>
                <ng-container *ngIf="selectedCardPresentTemplate" class="pt-6">
                  <card-present-pricing
                    [showCardPresent]="true"
                    [currency]="selectedCardPresentTemplate.currency"
                    [feeType]="selectedCardPresentTemplate.fee_type"
                    [visaRate]="selectedCardPresentTemplate.visa_rate"
                    [amexRate]="selectedCardPresentTemplate.amex_rate"
                    [transactionFee]="selectedCardPresentTemplate.transaction_fee"
                    [chargebackFee]="selectedCardPresentTemplate.chargeback_fee"
                    [retrievalFee]="selectedCardPresentTemplate.retrieval_fee"
                    [reversalFee]="selectedCardPresentTemplate.reversal_fee"
                    [accountMonthlyFee]="selectedCardPresentTemplate.account_monthly_fee"
                    [accountMonthlyMinimumFee]="selectedCardPresentTemplate.account_monthly_minimum_fee"
                    [bankAccountChangeFee]="selectedCardPresentTemplate.bank_account_change_fee"
                    [monthlyTerminalFee]="selectedCardPresentTemplate.monthly_terminal_fee"
                    [passThroughFees]="selectedCardPresentTemplate.pass_through_fees"
                  ></card-present-pricing>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div *ngIf="hasDebitCapability" class="pt-5">
            <mat-slide-toggle
              data-test-id="merchant-account-dd-option"
              color="primary"
              (change)="debitToggled($event)"
              [(ngModel)]="debitIsChecked"
              [ngModelOptions]="{ standalone: true }"
            >
              <tilled-paragraph-p3>Bank-to-bank processing</tilled-paragraph-p3>
            </mat-slide-toggle>
            <ng-container *ngIf="debitIsChecked">
              <div class="flex min-w-0 flex-auto flex-col">
                <tilled-select
                  [multiple]="false"
                  classOverride="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full my-4"
                  data-test-id="merchant-account-dd-pricing"
                  [options]="debitPricingTemplateOptions"
                  [controlName]="connectedAccountForm.get('debitPricingTemplate')"
                  [placeholder]="'Select bank-to-bank pricing'"
                  [errors]="
                    connectedAccountForm.get('debitPricingTemplate').hasError('required') &&
                    connectedAccountForm.get('debitPricingTemplate').touched
                  "
                  errorMessage="Bank-to-bank pricing is required if selected"
                  (selectionChange)="updateSelectedDebit($event)"
                ></tilled-select>
                <ng-container *ngIf="selectedDebitTemplate">
                  <debit-pricing
                    [showCard]="true"
                    [currency]="selectedDebitTemplate.currency"
                    [feeType]="selectedDebitTemplate.fee_type"
                    [transactionFee]="selectedDebitTemplate.transaction_fee"
                    [returnFee]="selectedDebitTemplate.return_fee"
                    [accountMonthlyFee]="selectedDebitTemplate.account_monthly_fee"
                    [accountMonthlyMinimumFee]="selectedDebitTemplate.account_monthly_minimum_fee"
                  ></debit-pricing>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</form-card>
