/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CardChecks { 
    /**
     * If an address line1 was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`.
     */
    address_line1_check?: CardChecks.AddressLine1CheckEnum;
    /**
     * If an address postal code was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`.
     */
    address_postal_code_check?: CardChecks.AddressPostalCodeCheckEnum;
    /**
     * If a CVC was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`.
     */
    cvc_check?: CardChecks.CvcCheckEnum;
}
export namespace CardChecks {
    export type AddressLine1CheckEnum = 'pass' | 'fail' | 'unavailable' | 'unchecked';
    export const AddressLine1CheckEnum = {
        PASS: 'pass' as AddressLine1CheckEnum,
        FAIL: 'fail' as AddressLine1CheckEnum,
        UNAVAILABLE: 'unavailable' as AddressLine1CheckEnum,
        UNCHECKED: 'unchecked' as AddressLine1CheckEnum
    };
    export type AddressPostalCodeCheckEnum = 'pass' | 'fail' | 'unavailable' | 'unchecked';
    export const AddressPostalCodeCheckEnum = {
        PASS: 'pass' as AddressPostalCodeCheckEnum,
        FAIL: 'fail' as AddressPostalCodeCheckEnum,
        UNAVAILABLE: 'unavailable' as AddressPostalCodeCheckEnum,
        UNCHECKED: 'unchecked' as AddressPostalCodeCheckEnum
    };
    export type CvcCheckEnum = 'pass' | 'fail' | 'unavailable' | 'unchecked';
    export const CvcCheckEnum = {
        PASS: 'pass' as CvcCheckEnum,
        FAIL: 'fail' as CvcCheckEnum,
        UNAVAILABLE: 'unavailable' as CvcCheckEnum,
        UNCHECKED: 'unchecked' as CvcCheckEnum
    };
}


