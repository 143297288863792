<div class="flex items-center">
  <form [formGroup]="globalSearchForm">
    <mat-form-field class="fuse-mat-dense w-full sm:min-w-50" [floatLabel]="'always'" [appearance]="'fill'">
      <mat-icon class="mr-2 icon-size-4" [svgIcon]="'mat_outline:search'"></mat-icon>

      <input
        data-test-id="global-search-selector"
        [placeholder]="'Search by unique ID'"
        #globalSearchInput
        matInput
        [formControlName]="'globalSearchInput'"
        class="text-secondary text-p3"
        (keyup.enter)="onEnter()"
        [matTooltip]="tooltip"
      />
    </mat-form-field>
  </form>
</div>
