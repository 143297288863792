<div class="top-of-form"></div>
<merchant-app-card
  [title]="'Processing volumes'"
  [description]="'Enter your expected processing volumes.'"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <div class="mb-2" [attr.style]="'width: ' + tilledInputWidth">
    <merchant-app-alert
      *ngIf="reviewAlert"
      data-test-id="processing-volume-avg-txn-alert"
      alertName="processingVolumesWarnAlertBox"
      [title]="reviewAlert.title"
      [description]="reviewAlert.message"
      [hideEdit]="true"
      class="-mt-5 mb-6 flex flex-col whitespace-pre-wrap"
    ></merchant-app-alert>
    <div class="flex items-center">
      <tilled-heading-h5 class="self-center whitespace-nowrap pr-2 leading-normal tracking-normal">
        Processing volume
      </tilled-heading-h5>
      <mat-divider class="w-full"></mat-divider>
    </div>
  </div>
  <form data-test-id="processing-volumes-step" class="w-full" [formGroup]="processingVolumesForm">
    <div class="flex w-full flex-col">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-monthly-transactions"
        name="averageMonthlyTransactions"
        mask="separator.0"
        label="Number of Transactions per month"
        tilledLabel="true"
        hint="Estimate the average number of credit card or bank-to-bank transactions you make in a month."
        [errors]="
          processingVolumesForm.get('averageMonthlyTransactions').hasError('min') &&
          processingVolumesForm.get('averageMonthlyTransactions').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
    <div class="flex flex-col">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-monthly-volume"
        name="averageMonthlyVolume"
        showCurrency="'true'"
        mask="separator.0"
        label="Monthly Processing Volume"
        tilledLabel="true"
        hint="Estimate the average amount you make in a month from credit card and bank-to-bank sales."
        [errors]="
          processingVolumesForm.get('averageMonthlyVolume').touched &&
          (processingVolumesForm.get('averageMonthlyVolume').hasError('max') ||
            processingVolumesForm.get('averageMonthlyVolume').hasError('min'))
        "
        [errorMessage]="
          processingVolumesForm.get('averageMonthlyVolume').hasError('min')
            ? 'Must be greater than $' + monthMin
            : processingVolumesForm.get('averageMonthlyVolume').hasError('max')
              ? 'Must be less than $' + monthMax
              : 'Test'
        "
      ></tilled-input>
    </div>
    <!-- Average Transaction Amounts -->
    <div class="mb-4 mt-6 flex flex-row items-center" [attr.style]="'width: ' + tilledInputWidth">
      <tilled-heading-h5 class="self-center whitespace-nowrap pr-2 leading-normal tracking-normal">
        Average transaction amounts
      </tilled-heading-h5>
      <mat-divider class="w-full"></mat-divider>
    </div>

    <div class="items-left flex flex-col" [ngClass]="!forConsole ? 'w-5/12' : 'w-full'">
      <tilled-paragraph-p3 [secondary]="true">
        If you process a wide range of transaction amounts, consider estimating your average on the higher end to avoid
        delays in funds disbursement.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasCard" [formGroup]="processingVolumesForm">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-transaction-amount-card"
        name="averageTransactionAmountCard"
        showCurrency="'true'"
        mask="separator.0"
        label="Credit Card - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single credit card sale or transaction."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountCard').touched &&
          (processingVolumesForm.get('averageTransactionAmountCard').hasError('max') ||
            processingVolumesForm.get('averageTransactionAmountCard').hasError('min'))
        "
        [errorMessage]="
          processingVolumesForm.get('averageTransactionAmountCard').hasError('min')
            ? 'Must be greater than $' + cardMin
            : processingVolumesForm.get('averageTransactionAmountCard').hasError('max')
              ? 'Must be less than $' + cardMax
              : 'Test'
        "
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasDebit" [formGroup]="processingVolumesForm">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-transaction-amount-debit"
        name="averageTransactionAmountDebit"
        showCurrency="'true'"
        mask="separator.0"
        label="Bank-to-bank - Average Transaction Amount"
        [sentenceCaseTitle]="false"
        tilledLabel="true"
        hint="Estimate the typical amount for a single bank-to-bank sale or transaction."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountDebit').touched &&
          (processingVolumesForm.get('averageTransactionAmountDebit').hasError('max') ||
            processingVolumesForm.get('averageTransactionAmountDebit').hasError('min'))
        "
        [errorMessage]="
          processingVolumesForm.get('averageTransactionAmountDebit').hasError('min')
            ? 'Must be greater than $' + debitMin
            : processingVolumesForm.get('averageTransactionAmountDebit').hasError('max')
              ? 'Must be less than $' + debitMax
              : 'Test'
        "
      ></tilled-input>
    </div>
  </form>
</merchant-app-card>
