import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/services/auth.service';
import { BehaviorSubject, Observable, firstValueFrom, interval, lastValueFrom, of } from 'rxjs';
import { catchError, defaultIfEmpty, filter, switchMap, take, tap } from 'rxjs/operators';
import {
  CreateMiddeskBusinessRequestParams,
  InternalService,
  MiddeskBusiness,
  OnboardingService,
  UpdateMiddeskBusinessRequestParams,
} from '../../../../projects/tilled-api-client/src';

@Injectable({
  providedIn: 'root',
})
export class MiddeskAppService {
  private _middeskBusinessSubject = new BehaviorSubject<MiddeskBusiness | null>(null);
  public middeskBusiness$: Observable<MiddeskBusiness | null> = this._middeskBusinessSubject.asObservable();
  public middeskEnabled$ = new BehaviorSubject<boolean | null>(null);

  constructor(
    private _internalService: InternalService,
    private _onboardingService: OnboardingService,
  ) {
    this.isMiddeskEnabled(AuthService.getCurrentAccountId());
  }

  public getMiddeskBusiness(accountId: string): void {
    this.isMiddeskEnabled(AuthService.getCurrentAccountId())
      .pipe(
        take(1),
        switchMap((enabled) => {
          if (enabled) {
            return this._onboardingService.getMiddeskBusiness({ tilledAccount: accountId }).pipe(
              tap((business) => this._middeskBusinessSubject.next(business)),
              catchError(() => {
                this._middeskBusinessSubject.next(null);
                return of(null);
              }),
            );
          }
          return of(null);
        }),
      )
      .subscribe();
  }

  public async createMiddeskBusiness(params: CreateMiddeskBusinessRequestParams): Promise<MiddeskBusiness | null> {
    const enabled = await firstValueFrom(this.isMiddeskEnabled(AuthService.getCurrentAccountId()));

    if (!enabled) {
      return null;
    }

    const response = (await lastValueFrom(
      this._onboardingService.createMiddeskBusiness(params),
    )) as MiddeskBusinessWithVerification;

    if (
      response?.verification?.legalNameStatus &&
      response?.verification?.tinStatus &&
      response?.verification?.addressStatus
    ) {
      return response;
    }

    // Poll for verification status, try 3 times with a 500ms interval
    return firstValueFrom(
      interval(500).pipe(
        take(3),
        switchMap(() => this._onboardingService.getMiddeskBusiness({ tilledAccount: params.tilledAccount })),
        filter((business: MiddeskBusinessWithVerification) => {
          return (
            business?.verification?.legalNameStatus !== undefined &&
            business?.verification?.tinStatus !== undefined &&
            business?.verification?.addressStatus !== undefined
          );
        }),
        defaultIfEmpty(response),
        catchError(() => {
          return of(null);
        }),
      ),
    );
  }

  public async updateMiddeskBusiness(params: UpdateMiddeskBusinessRequestParams): Promise<MiddeskBusiness | null> {
    const enabled = await firstValueFrom(this.isMiddeskEnabled(AuthService.getCurrentAccountId()));

    if (!enabled) {
      return null;
    }

    return await firstValueFrom(
      this._onboardingService.updateMiddeskBusiness(params).pipe(
        tap((business) => this._middeskBusinessSubject.next(business)),
        catchError(() => {
          this._middeskBusinessSubject.next(null);
          return of(null);
        }),
      ),
    );
  }

  // TEMPORARY check for Middesk feature flag
  public isMiddeskEnabled(accountId: string): Observable<boolean> {
    // Return value if already set (to avoid multiple API calls)
    if (this.middeskEnabled$.value !== null) {
      return this.middeskEnabled$.asObservable();
    }
    this._internalService
      .internalMerchantOrPartnerHasFeature({
        tilledAccount: accountId,
        id: 'middesk.integration.enabled',
      })
      .pipe(
        catchError(() => {
          return of(false);
        }),
      )
      .subscribe((enabled) => {
        this.middeskEnabled$.next(enabled);
      });
    return this.middeskEnabled$.asObservable();
  }
}

export interface MiddeskBusinessWithVerification extends MiddeskBusiness {
  verification: MiddeskBusinessVerification;
}

export interface MiddeskBusinessVerification {
  legalNameVerified: boolean;
  legalNameStatus?: LegalNameVerificationStatus;
  tinVerified: boolean;
  tinStatus?: TinVerificationStatus;
  addressVerified: boolean;
  addressStatus?: AddressVerificationStatus;
}

export enum LegalNameVerificationStatus {
  VERIFIED = 'Verified',
  SIMILAR_MATCH = 'Similar Match',
  ALTERNATE_NAME = 'Alternate Name',
  UNVERIFIED = 'Unverified',
}

export enum TinVerificationStatus {
  FOUND = 'Found',
  WARNING = 'Warning',
  MISMATCH = 'Mismatch',
  NOT_FOUND = 'Not Found',
  ERROR = 'Error',
}

export enum AddressVerificationStatus {
  VERIFIED = 'Verified',
  APPROXIMATE_MATCH = 'Approximate Match',
  SIMILAR_MATCH = 'Similar Match',
  INCOMPLETE_MATCH = 'Incomplete Match',
  UNVERIFIED = 'Unverified',
}
