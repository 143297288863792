/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MiddeskSuggestions } from './middeskSuggestions';


export interface MiddeskBusiness { 
    /**
     * The ID of the Tilled Account associated with this Middesk business.
     */
    account_id: string;
    /**
     * Addresses associated with the business.
     */
    business_addresses: Array<object>;
    /**
     * The ID of the Middesk business associated with this merchant.
     */
    business_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Doing Business As (DBA) name of the business.
     */
    dba_name_of_business?: string;
    /**
     * Entity type of the business.
     */
    entity_type: MiddeskBusiness.EntityTypeEnum;
    /**
     * Date when the business was formed.
     */
    formation_date?: string;
    /**
     * State where the business was formed.
     */
    formation_state?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Legal name of the business.
     */
    legal_name_of_business?: string;
    /**
     * Status of the business.
     */
    status: MiddeskBusiness.StatusEnum;
    /**
     * Whether the merchant has accepted or ignored a specific suggestion in the merchant application. This is so that we do not display suggestions that the merchant has already accepted or ignored in a previous step.
     */
    suggestions: MiddeskSuggestions;
    /**
     * Tax Identification Number (TIN) of the business.
     */
    tin: string;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Verification details of the business.
     */
    verification: object;
}
export namespace MiddeskBusiness {
    export type EntityTypeEnum = 'CORPORATION' | 'LLC' | 'PARTNERSHIP' | 'SOLE PROPRIETORSHIP' | 'NON-PROFIT' | 'UNKNOWN' | 'TRUST' | 'AGENT';
    export const EntityTypeEnum = {
        CORPORATION: 'CORPORATION' as EntityTypeEnum,
        LLC: 'LLC' as EntityTypeEnum,
        PARTNERSHIP: 'PARTNERSHIP' as EntityTypeEnum,
        SOLE_PROPRIETORSHIP: 'SOLE PROPRIETORSHIP' as EntityTypeEnum,
        NON_PROFIT: 'NON-PROFIT' as EntityTypeEnum,
        UNKNOWN: 'UNKNOWN' as EntityTypeEnum,
        TRUST: 'TRUST' as EntityTypeEnum,
        AGENT: 'AGENT' as EntityTypeEnum
    };
    export type StatusEnum = 'open' | 'pending' | 'in_audit' | 'in_review' | 'approved' | 'rejected';
    export const StatusEnum = {
        OPEN: 'open' as StatusEnum,
        PENDING: 'pending' as StatusEnum,
        IN_AUDIT: 'in_audit' as StatusEnum,
        IN_REVIEW: 'in_review' as StatusEnum,
        APPROVED: 'approved' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum
    };
}


